.abt_NFTGOTIX {
  .customContainer {
    position: relative;
    // padding-left: 50px;
    // padding-right: 50px;

    // @include responsivemax(575) {
    //   padding-left: 15px;
    //   padding-right: 15px;
    // }
    .abt_art {
      position: relative;
      margin-bottom: 40px;

      @include responsivemax(575) {
        margin-bottom: 0px;
      }

      .fodj {
        h4 {
          font-size: 26px;
          line-height: 30px;
          letter-spacing: 1px;
          margin-bottom: 0;
          font-weight: 600;
          margin-bottom: 20px;
          //   font-family: 'KTF Rublena';

          @include responsivemax(575) {
            font-size: 18px;
            color: #a6a6a6;
            margin-bottom: 10px;
          }
        }

        p {
          font-size: 18px;
          line-height: 24px;
          color: #fff;
        }
      }
    }
  }
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}

.djhrhd ol li,
.djhrhd ul li {
  color: #fff;
}
.abt_art h4 {
  color: $purpleDark !important;
}

.abt_art .regular_p {
  color: #fff;
}

.djhrhd {
  h4 {
    font-size: 20px;
    font-weight: 600;
    // font-family: 'KTF Rublena';
    letter-spacing: 1px;
    line-height: 26px;
  }
  p {
    color: #fff;
    margin-bottom: 0.5rem;
    font-weight: 400 !important;
  }

  ul {
    li {
      margin-bottom: 16px;
    }
  }

  ol {
    li {
      margin-bottom: 16px;
    }
  }
  ol,
  ul,
  menu {
    list-style: revert;
    padding-left: 2rem;
  }
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }
}
.dkejdh tr {
}
.dkejdh tr td {
  border: 1px solid #acace6;
  padding: 15px;
}
.regular_p {
  margin-bottom: 16px;
}
