$primary_color: rgb(2 132 199);
$primary_color_hover: rgb(5, 109, 160);
$primary_color2: #c8deff;
$dark_blue: #0530ca;
$secondary_color: #e1e3ea;
$secondary_color_hover: #eff0f5;
$gray: #f6f8ff;
$secondary_purple: #e0eaff;
$lightGray: #f2f2f2;
$white: #fff;
$black: #000;
$darkGray: #4d4d4d;
$theme_green: #50cd89;
$theme_green_hover: #47be7d;
$theme_red: #db1212;
$border_ring: #f6f6f6;
$table_row_color: rgba(75, 114, 182, 0.2);

$font_family_inter: 'Inter', Helvetica, Arial, sans-serif !important;

$n_secondary_border: rgb(55, 65, 81);
$n_background_color: rgb(17, 24, 39);
$purple: #a6abef;
$font_barlow: 'Barlow', sans-serif;
$purpleLight: rgb(178, 116, 253);
$purpleDark: rgb(146 59 255);

$megenta: #c646b1;
$muted: #908f8f;

$eventActive: #dbeafd;
$eventActiveDark: #dbeafd;
$tourActive: rgb(147 51 234);
// Font Families
$inter: 'Inter', sans-serif;
$abhaya: 'Abhaya Libre', serif;

@for $i from 0 through 50 {
  [class*='fsize-#{$i}'] {
    font-size: #{$i}px !important;
  }

  [class*='round-#{$i}'] {
    border-radius: #{$i}px !important;
  }

  [class*='roundrem-#{$i}'] {
    $j: calc($i/10);
    border-radius: #{$j}rem !important;
  }

  $percent: '%';

  // Percentage
  [class*='w-per-#{$i}'] {
    width: #{$i}+ $percent !important;
  }

  [class*='mxp-width-#{$i}'] {
    max-width: #{$i}px !important;
  }

  [class*='h-per-#{$i}'] {
    height: #{$i}+ $percent !important;
  }

  [class*='wpx-#{$i}'] {
    width: #{$i}px !important;
  }

  [class*='hpx-#{$i}'] {
    height: #{$i}px !important;
  }

  [class*='hvh-#{$i}'] {
    height: #{$i}vh !important;
  }

  [class*='wrem-#{$i}'] {
    width: #{$i}rem !important;
  }

  [class*='hrem-#{$i}'] {
    height: #{$i}rem !important;
  }

  /* Positions */
  [class*='topPx-#{$i}'] {
    top: #{$i}px;
  }

  [class*='leftPx-#{$i}'] {
    left: #{$i}px;
  }

  [class*='rightPx-#{$i}'] {
    right: #{$i}px;
  }

  [class*='bottomPx-#{$i}'] {
    bottom: #{$i}px;
  }

  // Percentage
  [class*='top-percent-#{$i}'] {
    top: #{$i}+ $percent;
  }

  [class*='left-percent-#{$i}'] {
    left: #{$i}+ $percent;
  }

  [class*='right-percent-#{$i}'] {
    right: #{$i}+ $percent;
  }

  [class*='bottom-percent-#{$i}'] {
    bottom: #{$i}+ $percent;
  }

  [class*='translate-neg-#{$i}-#{$i}'] {
    transform: translate(-#{$i}+ $percent, -#{$i}+ $percent);
  }

  // Position Rem
  [class*='top-rem-#{$i}'] {
    top: #{$i}rem;
  }

  [class*='left-rem-#{$i}'] {
    left: #{$i}rem;
  }

  [class*='right-rem-#{$i}'] {
    right: #{$i}rem;
  }

  [class*='bottom-rem-#{$i}'] {
    bottom: #{$i}rem;
  }

  [class*='mbPx-#{$i}'] {
    margin-bottom: #{$i}px;
  }

  [class*='mtPx-#{$i}'] {
    margin-top: #{$i}px;
  }

  // [class*='truncateLine-#{$i}'] {
  //   -webkit-line-clamp: #{$i};
  // }

  // zindex
  [class*='zindex-#{$i}'] {
    z-index: #{$i} !important;
  }

  [class*='maxWidth-#{$i}'] {
    max-width: #{$i}rem !important;
  }
  [class*='minWidth-#{$i}'] {
    min-width: #{$i}rem !important;
  }
}

.bgLight {
  background-color: $white !important;
}

.textBColor {
  color: $black !important;
}

.themeTextGreen {
  color: $theme_green !important;
}

.themeTextRed {
  color: $theme_red !important;
}

.textWColor {
  color: $white;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
}

.secondaryBttn {
  background-color: $secondary_purple;
}

.hoverLink {
  &:hover {
    color: $primary_color !important;
  }
}
