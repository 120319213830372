// .pageLoader {
//   background-color: $white;
// }

// .loader-bg {
//   .pageLoader {
//     backdrop-filter: blur(16px) saturate(180%);
//     -webkit-backdrop-filter: blur(16px) saturate(180%);
//     background-color: rgba(0, 0, 0, 0.843) !important;

//     .loader_darkText {
//       color: $white;
//     }
//     .puff_darkText {
//       span {
//         border-color: $white !important;
//       }
//     }
//   }
// }

.darkLoader {
  .pageLoader {
    background-color: black !important;
  }
}

.loaders {
  // position: fixed;
  // left: 0;
  // top: 0;
  // background: black;
  width: 100%;
  height: 100vh;
  // animation: backgroundChange 0.5s linear 3s forwards;
}

.load-text {
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  // position: absolute;
  // font-size: 7vmax;
  font-size: 7.8vmax;
  // width: fit-content;
  // display: flex;
  animation: colorChange 0.5s linear 3s forwards 0.4s;
}

.loading-text {
  width: 0;
  overflow: hidden;
  animation: expand 1s linear 1.5s forwards;
  white-space: nowrap;
}

// main {
//   box-sizing: border-box;
//   background-color: black;
//   color: white;
//   min-height: 100vh;
//   font-family: 'Open Sans', sans-serif;
// }

@keyframes scaleText {
  from {
    transform: scale(1.5);
  }
  to {
    transform: scale(1);
  }
}

@keyframes expand {
  from {
    width: 0;
  }
  to {
    width: 30vmax;
  }
}

// @keyframes backgroundChange {
//   from {
//     background-color: white;
//   }
//   to {
//     background-color: black;
//   }
// }

@keyframes colorChange {
  from {
    color: black;
  }
  to {
    color: white;
  }
}

.loaded-text {
  transform: scale(1.5);
  animation: scaleText 0.5s linear 0.5s forwards;
}

@keyframes animatedText {
  // to {
  //   background-position: 4500vh;
  // }

  from {
    background-image: -webkit-linear-gradient(91.75deg, #fff);
    background-image: linear-gradient(91.75deg, #fff);
  }
  to {
    background-position: -100%;
  }
}
.btn-shine {
  background: linear-gradient(to right, #4d4d4d 0, #fff 10%, #4d4d4d 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 7s infinite linear forwards reverse;
  // animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  background-size: 300%;
  background-position: -100%;
}

@-moz-keyframes shine {
  0% {
    background-position: -100%;
  }
  40% {
    background-position: 30%;
  }
  60% {
    background-position: 50%;
  }
  80% {
    background-position: 80%;
  }
  100% {
    background-position: 100%;
  }
}
@-webkit-keyframes shine {
  0% {
    background-position: -100%;
  }
  40% {
    background-position: 30%;
  }
  60% {
    background-position: 50%;
  }
  80% {
    background-position: 80%;
  }
  100% {
    background-position: 100%;
  }
}
@-o-keyframes shine {
  0% {
    background-position: -100%;
  }
  40% {
    background-position: 30%;
  }
  60% {
    background-position: 50%;
  }
  80% {
    background-position: 80%;
  }
  100% {
    background-position: 100%;
  }
}
@keyframes shine {
  0% {
    background-position: -100%;
  }
  40% {
    background-position: 30%;
  }
  60% {
    background-position: 50%;
  }
  80% {
    background-position: 80%;
  }
  100% {
    background-position: 100%;
  }
}

#logo-gradient {
  animation: slideOver 5s linear infinite;
}
@keyframes slideOver {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(100%);
  }
  75% {
    transform: translateX(0);
  }
}
.fadeOut {
  animation: fadeOutAni 5s 1 ease;
  animation-fill-mode: forwards;
}
// @keyframes fadeOutAni {
//   0% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//   }
// }
// Css Fade In List

.fade-item {
  color: #fff;
  transition: 2s all ease-in-out;
  opacity: 0;
  .icon_Color {
    color: #fff;
    animation: fadeInColor 0.9s 1;
    animation-fill-mode: forwards;
  }
}

.fadein {
  .icon_Color {
    color: #3de469;
  }
  animation: fadeIn 0.9s 1;
  animation-fill-mode: forwards;
}
.fadeOutList {
  .icon_Color {
    color: #3de469;
  }
  animation: fadeOutList 4s 1;
  animation-fill-mode: forwards;

  &:last-of-type {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutList {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeInColor {
  0% {
    color: #fff;
  }
  100% {
    color: #3de469;
  }
}

// Fade in Close List

.hashLoader {
  span {
    &:first-child {
      animation: 2s ease 0s infinite normal none running
        react-spinners-HashLoader-before-new !important;
    }
    &:last-child {
      animation: 2s ease 0s infinite normal none running
        react-spinners-HashLoader-after-new !important;
    }
  }
}
$colorAnim: #0f79aebf;
@keyframes react-spinners-HashLoader-before-new {
  0% {
    width: 4.4px;
    box-shadow: $colorAnim 8.8px -4.4px, rgb(40, 218, 40) -8.8px 4.4px;
  }
  35% {
    width: 22px;
    box-shadow: rgba(50, 50, 182, 0.863) 0px -4.4px, $colorAnim 0px 4.4px;
  }
  70% {
    width: 4.4px;
    box-shadow: $colorAnim 8.8px -4.4px, $colorAnim 8.8px 4.4px;
  }
  100% {
    box-shadow: rgba(50, 50, 182, 0.863) 8.8px -4.4px,
      rgba(50, 50, 182, 0.863) -8.8px 4.4px;
  }
}

@keyframes react-spinners-HashLoader-after-new {
  0% {
    height: 4.4px;
    box-shadow: $colorAnim 4.4px 8.8px, rgba(50, 50, 182, 0.863) -4.4px -8.8px;
  }
  35% {
    height: 22px;
    box-shadow: rgb(192, 3, 3) 4.4px 0px, rgb(40, 218, 40) -4.4px 0px;
  }
  70% {
    height: 4.4px;
    box-shadow: $colorAnim 4.4px -8.8px, $colorAnim -4.4px 8.8px;
  }
  100% {
    box-shadow: rgba(50, 50, 182, 0.863) 4.4px 8.8px,
      rgba(50, 50, 182, 0.863) -4.4px -8.8px;
  }
}
// $borderWidth: 3px;

// .gradient-border {
//   background: #1d1f20;
//   position: relative;
//   border-radius: $borderWidth;
// }
// .gradient-border:after {
//   content: '';
//   position: absolute;
//   top: calc(-1 * $borderWidth);
//   left: calc(-1 * $borderWidth);
//   height: calc(100% + $borderWidth * 2);
//   width: calc(100% + $borderWidth * 2);
//   background: linear-gradient(
//     60deg,
//     #f79533,
//     #f37055,
//     #ef4e7b,
//     #a166ab,
//     #5073b8,
//     #1098ad,
//     #07b39b,
//     #6fba82
//   );
//   border-radius: calc(2 * $borderWidth);
//   z-index: -1;
//   animation: animatedgradient 3s ease alternate infinite;
//   background-size: 300% 300%;
// }

// @keyframes animatedgradient {
//   0% {
//     background-position: 0% 50%;
//   }
//   50% {
//     background-position: 100% 50%;
//   }
//   100% {
//     background-position: 0% 50%;
//   }
// }
// .gradient-border {
//   position: relative;
// }
// .gradient-border:after {
//   content: '';
//   display: block;
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 100%;
//   z-index: -2;
//   transform: rotate(0deg);
//   animation: gradient 2s ease alternate infinite;
//   // -webkit-animation-play-state: paused;
//   // animation-play-state: paused;
//   -webkit-animation-play-state: running;
//   animation-play-state: running;
// }
// .gradient-border:after {
//   background: #6086c1;
//   background: -moz-linear-gradient(
//     45deg,
//     #6086c1 0%,
//     #efe097 70%,
//     #d66466 100%
//   );
//   background: -webkit-gradient(
//     linear,
//     left bottom,
//     right top,
//     color-stop(0%, #6086c1),
//     color-stop(70%, #efe097),
//     color-stop(100%, #d66466)
//   );
//   background: -webkit-linear-gradient(
//     45deg,
//     #6086c1 0%,
//     #efe097 70%,
//     #d66466 100%
//   );
//   background: -o-linear-gradient(45deg, #6086c1 0%, #efe097 70%, #d66466 100%);
//   background: -ms-linear-gradient(45deg, #6086c1 0%, #efe097 70%, #d66466 100%);
//   background: linear-gradient(45deg, #6086c1 0%, #efe097 70%, #d66466 100%);
//   background-size: 300% 300%;
// }
// @-webkit-keyframes gradient {
//   0% {
//     background-position: 0% 50%;
//   }
//   50% {
//     background-position: 95% 50%;
//   }
//   100% {
//     background-position: 0% 50%;
//   }
// }
// @keyframes gradient {
//   0% {
//     background-position: 0% 50%;
//   }
//   50% {
//     background-position: 95% 50%;
//   }
//   100% {
//     background-position: 0% 50%;
//   }
// }

.animatedBttn:before {
  content: '';
  position: absolute;
  background: linear-gradient(
    45deg,
    rgb(238, 16, 16) 0%,
    #6697f3 30%,
    rgb(99, 170, 228) 40%,
    #66f379 80%,
    #060708 100%
  );
  top: -2px;
  height: 100%;
  width: 50%;
  left: -2px;
  z-index: 0;
  -webkit-animation: buttonFirstHalf 5s linear infinite;
  animation: buttonFirstHalf 5s linear infinite;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.dark {
  .animatedBttn:before {
    content: '';
    position: absolute;
    background: linear-gradient(
      45deg,
      rgb(238, 16, 16) 0%,
      #3b73dd 30%,
      rgb(111, 186, 247) 40%,
      rgb(245, 230, 93) 55%,
      #07a51c 80%,
      #060708 100%
    );
  }
}
// .animatedBttn:after {
//   content: '';
//   position: absolute;
//   background: linear-gradient(
//     60deg,
//     rgba(6, 7, 8, 0) 0%,
//     #c34dbb 30%,
//     #8062e2 65%,
//     #c34dbb 50%,
//     rgba(6, 7, 8, 0)
//   );
//   right: -2px;
//   bottom: -2px;
//   z-index: 0;
//   -webkit-animation: buttonSecondHalf 5s linear infinite;
//   animation: buttonSecondHalf 5s linear infinite;
//   border-radius: 5px;
//   -webkit-border-radius: 5px;
//   -moz-border-radius: 5px;
//   -ms-border-radius: 5px;
//   -o-border-radius: 5px;
// }

@keyframes buttonFirstHalf {
  0% {
    width: 20%;
    height: 80%;
    top: -5%;
    left: -2%;
  }
  16.7% {
    top: -5%;
    left: -2%;
    height: 50%;
    width: 50%;
  }
  33.3% {
    top: -5%;
    left: 0%;
    height: 50%;
    width: 80%;
  }
  50% {
    top: -5%;
    width: 20%;
    height: 80%;
    left: 81%;
  }
  66.7% {
    top: 55%;
    width: 50%;
    height: 50%;
    left: 51%;
  }
  83.3% {
    top: 55%;
    left: -2%;
    width: 80%;
    height: 50%;
  }
  100% {
    top: 22%;
    left: -2%;
    width: 20%;
    height: 80%;
  }
}

@keyframes buttonSecondHalf {
  0% {
    top: 24%;
    left: 82%;
    width: 20%;
    height: 80%;
  }
  16.7% {
    top: 55%;
    left: 50%;
    width: 50%;
    height: 50%;
  }
  33.3% {
    top: 55%;
    left: 0%;
    width: 80%;
    height: 50%;
  }
  50% {
    left: -2%;
    height: 80%;
    width: 20%;
    top: 24%;
  }
  66.7% {
    top: -5%;
    width: 20%;
    height: 50%;
    left: -2%;
  }
  83.3% {
    top: -5%;
    width: 80%;
    height: 20%;
    left: 20%;
  }
  100% {
    top: -5%;
    height: 80%;
    width: 20%;
    left: 82%;
  }
}

.flipLogo {
  animation: rotation 3s infinite ease;
  // transform: rotate(0deg);
  // transform: rotate(-30deg);
}
/* WebKit and Opera browsers */
@-webkit-keyframes spinner {
  // from {
  //   -webkit-transform: rotate(0deg);
  // }
  // to {
  //   -webkit-transform: rotate(-30deg);
  // }
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(30deg);
  }
} /* all other browsers */
@keyframes spinner {
  // from {
  //   -moz-transform: rotate(0deg);
  //   -ms-transform: rotate(0deg);
  //   transform: rotate(0deg);
  // }
  // to {
  //   -moz-transform: rotate(-30deg);
  //   -ms-transform: rotate(-30deg);
  //   transform: rotate(-30deg);
  // }
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(30deg);
  }
}
@keyframes rotation {
  100% {
    transform: rotateY(360deg);
  }
}

.featuredBgImg {
  span {
    overflow: hidden;
    // width: 18rem;
    // height: 18rem;
    // mix-blend-mode: multiply;
    // border-radius: 9999px;
    // opacity: 0.1;
    -webkit-filter: blur(64px);
    -moz-filter: blur(64px);
    -ms-filter: blur(64px);
    filter: blur(64px);
    -webkit-backdrop-filter: blur(64px);
    display: flex;
    // position: absolute;

    // @include responsivemin(1024) {
    //   width: 25rem;
    //   height: 25rem;
    // }

    // &.span1 {
    //   background-color: rgb(115, 8, 21);
    // }
    // &.span2 {
    //   background-color: rgb(61, 233, 239);
    //   margin-top: 10rem;
    //   margin-left: 10rem;
    // }
  }
}
.progressParent {
  height: 7px;
  border-radius: 2.5px;
  overflow: hidden;
  width: 100%;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  .progressBar {
    position: relative;
    height: 7px;
    overflow: hidden;
    transform: scaleX(0);
    animation: progressBarFillScale 20s cubic-bezier(0.01, 1.06, 0.71, 1)
      forwards;
    transform-origin: 0 0;
  }
  .childLoad {
    height: 100%;
    width: 400%;
    transform: translate(-1000px);
    animation: progressBarFillScale2 5s infinite linear;
  }
}
@keyframes progressBarFillScale {
  0% {
    transform: scaleX(149.001);
  }
  1% {
    transform: scaleX(0.001);
  }
  100% {
    transform: scaleX(0.97);
  }
}
@keyframes progressBarFillScale2 {
  0% {
    transform: translate(-1000px);
  }
  100% {
    transform: translate(1000px);
  }
}
