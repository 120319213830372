.tic_modal {
  .tic_modal_content {
    .ant-drawer-body {
      touch-action: manipulation;
      &::-webkit-scrollbar {
        width: 4px !important;
        height: 4px !important;
        cursor: pointer !important;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgb(2, 132, 199, 0.3) !important;
        border-radius: 20px;
        margin-top: 5px;
        margin-bottom: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $primary_color;
        // outline: 1px solid rgba(112, 128, 144, 0.212);
        border-radius: 20px;
      }
    }
  }
}

.smallTable {
  :where(.css-dev-only-do-not-override-1km3mtt).ant-table-wrapper
    .ant-table-thead
    > tr
    > th,
  :where(.css-dev-only-do-not-override-1km3mtt).ant-table-wrapper
    .ant-table-tbody
    > tr
    > td,
  :where(.css-dev-only-do-not-override-1km3mtt).ant-table-wrapper
    tfoot
    > tr
    > th,
  :where(.css-dev-only-do-not-override-1km3mtt).ant-table-wrapper
    tfoot
    > tr
    > td {
    padding: 10px 10px;
  }

  table {
    border-spacing: 0 3px !important;
    .ant-table-thead {
      .ant-table-cell {
        padding: 5px 5px;
      }
    }
  }
  .ant-table-body {
    overflow-y: auto !important;
  }
}

.gotix-drawer,
.custom_container {
  .ant-checkbox {
    .ant-checkbox-inner {
      border-color: #1677ff !important;
    }
    &:hover {
      .ant-checkbox-inner {
        background: #b8deff !important;
        // border-color: $theme_green_hover !important;
      }
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #1677ff !important;
      }
    }
    &.ant-checkbox-disabled {
      .ant-checkbox-inner {
        border-color: #d9d9d9 !important;
      }
    }
  }
}

.antd-popover {
  .ant-popover-inner {
    padding: 0 !important;
  }
}

/*============================= Notification Dropdown ==================*/
// .pulse-button {
//   box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5);
//   -webkit-animation: pulse 1.5s infinite;
// }

// .pulse-button:hover {
//   -webkit-animation: none;
// }

// @-webkit-keyframes pulse {
//   0% {
//     -moz-transform: scale(0.9);
//     -ms-transform: scale(0.9);
//     -webkit-transform: scale(0.9);
//     transform: scale(0.9);
//   }
//   70% {
//     -moz-transform: scale(1);
//     -ms-transform: scale(1);
//     -webkit-transform: scale(1);
//     transform: scale(1);
//     box-shadow: 0 0 0 50px rgba(255, 0, 0, 0);
//   }
//   100% {
//     -moz-transform: scale(0.9);
//     -ms-transform: scale(0.9);
//     -webkit-transform: scale(0.9);
//     transform: scale(0.9);
//     box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
//   }
// }

/*============================= Notification Dropdown ==================*/
