.textSmall {
  .text-\[11px\] {
    font-size: 9px !important;
  }
  @include responsivemin(1024) {
    .text-\[11px\] {
      font-size: 10px !important;
    }
  }
}
.select-city-drawer {
  border-radius: 30px 0px 0px 30px;

  .ant-drawer-header {
    border-bottom: solid 1px #ddd !important;
  }

  // .ant-drawer-close {
  //   position: absolute;
  //   right: 15px;
  //   top: -15px;

  //   button {
  //     background-color: #ddd !important;
  //     border: none !important;

  //     svg {
  //       fill: #000;
  //       color: #000;
  //     }
  //   }
  // }

  .ant-drawer-header-title {
    position: relative;
    left: -29px;

    .ant-drawer-title {
      div {
        padding: 0 !important;
        border: none !important;
        margin-left: 0 !important;

        &::after {
          display: none;
        }
      }

      .w-full {
        color: #fff;
      }
    }
  }

  .ant-drawer-body {
    padding: 0px !important;
  }

  .other-city-sep {
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #333;
      position: absolute;
      display: block;
    }
  }
  &.light-drawer {
    .ant-drawer-header {
      border-bottom: solid 1px #eee !important;
    }
    // .ant-drawer-close {
    //   button {
    //     svg {
    //       fill: #000;
    //       color: #000;
    //     }
    //   }
    // }
  }
}

.dark .select-city-drawer {
  border-radius: 30px 0px 0px 30px;

  .ant-drawer-header {
    border-bottom: solid 1px #222 !important;
  }

  // .ant-drawer-close {
  //   position: absolute;
  //   right: 15px;
  //   top: -15px;

  //   button {
  //     background-color: transparent !important;
  //     border: none !important;

  //     svg {
  //       fill: #fff;
  //       color: #fff;
  //     }
  //   }
  // }

  .ant-drawer-header-title {
    position: relative;

    .ant-drawer-title {
      div {
        padding: 0 !important;
        border: none !important;
        margin-left: 0 !important;

        &::after {
          display: none;
        }
      }

      .w-full {
        color: #fff;
      }
    }
  }

  .ant-drawer-body {
    padding: 0px !important;
  }

  .other-city-sep {
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #333;
      position: absolute;
      display: block;
    }
  }

  // &.light-drawer {
  //   .ant-drawer-header {
  //     border-bottom: solid 1px #333 !important;
  //   }
  //   .ant-drawer-close {
  //     button {
  //       svg {
  //         fill: #fff;
  //         color: #fff;
  //       }
  //     }
  //   }
  // }
}

.btn_remove {
  background-color: #a32929;
  color: #fff;
  border-radius: 20px;
  font-size: 12px;
  padding: 4px 12px;
}

.btn_remove:hover {
  background-color: #a32929;
}

.dark {
  .select-city-drawer {
    .ant-drawer-body {
      background-color: rgb(var(--color-base-2));
      box-shadow: 0px 15px -10px #000;
    }
  }
}

.dark .select-city-drawer {
  &.cart-drawer {
    .ant-drawer-header {
      width: 65%;
      background-color: rgb(var(--color-base-1));
      margin-left: 35%;
      border-radius: 25px 0 0 0;
      border-radius: 0px 0 0 0;
      border-bottom: solid 1px var(--color-border-3) !important;
      box-shadow: 0px 0px 15px 5px #000;
    }
    &.light-drawer {
      .ant-drawer-header {
        background-color: #222;
        border-bottom: solid 1px var(--color-border-3) !important;
        box-shadow: 0px 0px 0px 0px #fff;
      }
    }
  }

  .ant-drawer-body {
    background-color: rgb(var(--color-base-1));
    box-shadow: 0px 15px -10px #000;
  }

  .ant-drawer-footer {
    background-color: rgb(var(--color-base-1));
    border-top: solid 1px var(--color-border-3) !important;
  }
  &.cart-drawer {
    .ant-drawer-body {
      width: 65%;
      margin-left: 35%;
    }

    .ant-drawer-footer {
      width: 65%;
      margin-left: 35%;
    }
  }
  .you_may_like {
    position: absolute;
    left: 0;
    width: 35%;
    top: 0;
    // background-color: #333;
    background-color: rgb(var(--color-base-2));
  }
  &.light-drawer {
    .ant-drawer-body {
      background-color: #444;
      box-shadow: 0px 15px -10px #eee;
      border: none;
    }
    .ant-drawer-footer {
      background-color: #222;
      border-top: solid 1px #333 !important;
    }
  }
  &.dark-drawer {
    .ant-drawer-body {
      background-color: $black;
    }
    .ant-drawer-footer {
      background-color: #0a0a0a;
    }
  }
}
.select-city-drawer {
  &.cart-drawer {
    .ant-drawer-header {
      width: 65%;
      background-color: #f3f4f6;
      margin-left: 35%;
      border-radius: 25px 0 0 0;
      border-bottom: solid 1px #ddd !important;
      box-shadow: 0px 0px 15px 5px #ddd;
    }

    &.light-drawer {
      .ant-drawer-header {
        background-color: #fff;
        border-bottom: solid 1px #ddd !important;
        box-shadow: 0px 0px 0px 0px #fff;
      }
    }
  }

  .ant-drawer-body {
    background-color: #fff;
    box-shadow: 0px 15px -10px #000;
  }

  .ant-drawer-footer {
    background-color: #f3f4f6;
    border-top: solid 1px #ddd !important;
  }

  &.cart-drawer {
    .ant-drawer-body {
      width: 65%;
      margin-left: 35%;
    }

    .ant-drawer-footer {
      width: 65%;
      margin-left: 35%;
    }
  }

  .dark .you_may_like {
    position: absolute;
    left: 0;
    width: 35%;
    top: 0;
  }
  .you_may_like {
    position: absolute;
    left: 0;
    width: 35%;
    top: 0;
    background-color: #f2f2f2;
    height: 100vh;
  }

  &.light-drawer {
    .ant-drawer-body {
      background-color: #fff;
      box-shadow: 0px 15px -10px #eee;
    }

    .ant-drawer-footer {
      background-color: #fff;
      border-top: solid 1px #eee !important;
    }
  }

  &.dark-drawer {
    .ant-drawer-body {
      background-color: $black;
    }

    .ant-drawer-footer {
      background-color: #0a0a0a;
    }
  }
}
.root-drawer-light {
  .ant-drawer-content-wrapper {
    box-shadow: none;
  }
}

.artist_tab {
  // button {
  //     background-color: transparent;
  //   }
  button[data-headlessui-state='selected'] {
    background-color: #fff;
    box-shadow: -5px 1px 20px 0px #ddd !important;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: 0;
      left: 0;
      display: block;
    }
    &:focus {
      outline: none;
      box-shadow: none;
      border: none;
    }
  }
}
.dark .artist_tab {
  // button{
  //   background-color: transparent;
  // }
  button[data-headlessui-state='selected'] {
    box-shadow: -5px 1px 20px 0px #111 !important;
    position: relative;
    background-color: rgb(39 39 42 / var(--tw-bg-opacity));
    &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: 0;
      left: 0;
      display: block;
    }

    &:focus {
      outline: none;
      box-shadow: none;
      border: none;
    }
  }
}
.cstm-drpdwn {
  .ant-select-selector {
    color: #818181 !important;
    border-radius: 6px !important;
    font-size: 16px;
    background-color: transparent !important;
  }
  &:hover {
    border-color: #4096ff !important;
  }
  .ant-select-arrow {
    color: #818181 !important;
  }
  .ant-select-selection-item {
    color: #818181 !important;
  }
  &.cstm-drpdwn-transparent {
    .ant-select-selector {
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }
}

.dark {
  .darkInputPassword {
    .ant-input {
      color: $white;
    }
  }
}
.darkInputPassword {
  .ant-input {
    background-color: transparent;
    color: #0a0a0a;
    &::placeholder {
      color: rgb(148 163 184);
    }
  }
}
.antdCheckBox {
  .ant-form-item-control-input {
    min-height: 2px;
    margin-bottom: -5px;
  }
}
.antcstm {
  .ant-select-item-option-selected {
    background-color: #fff !important;
    color: #fff !important;
  }

  .ant-select-item {
    color: #000 !important;
  }
}
.dark .antcstm {
  .ant-select-item-option-selected {
    background-color: #111 !important;
    color: #fff !important;
  }

  .ant-select-item {
    color: #fff !important;
  }
}
.selectCursor-none {
  .ant-select-selection-search-input {
    cursor: none !important;
    caret-color: transparent !important;
    &:focus {
      outline: none;
    }
  }
}

// .ant-select-dropdown {
//   background-color: rgb(38 38 38) !important;
//   color: #fff !important;
// }

.payment-option {
  button[data-headlessui-state='open'] {
    border-radius: 8px 8px 0px 0px;
    border-bottom: solid 1px #403128;
  }
}
@media only screen and (min-width: 320px) and (max-width: 640px) {
  .select-city-drawer {
    border-radius: 25px 25px 0px 0px;
  }
  .select-city-drawer.cart-drawer {
    .ant-drawer-header {
      width: 100%;
      margin-left: 0%;
      border-radius: 25px 25px 0 0;
    }

    .ant-drawer-body {
      width: 100%;
      margin-left: 0%;
    }

    .ant-drawer-footer {
      width: 100%;
      margin-left: 0%;
    }

    .you_may_like {
      position: relative;
      left: 0;
      width: 100%;
      top: 0;
    }
  }

  .btn_remove {
    font-size: 14px;
    padding: 8px 12px;
  }
  .cstm-drpdwn {
    .ant-select-selector {
      font-size: 14px;
      .ant-select-selection-search-input {
        color: $white;
      }
    }
  }
}

.darkListViewTable {
  .ant-table {
    background: transparent;
    color: $white;
    .ant-table-thead {
      .ant-table-cell {
        border-radius: 0px !important;
        background: transparent;
        color: $white;
        border-color: #221f1f;

        &::before {
          // border-color: #221f1f;
          background-color: transparent !important;
        }
      }
    }
    .ant-table-tbody {
      .dataTablesRow {
        .ant-table-cell {
          border-radius: 0px !important;
          border-color: #221f1f;
        }
        .ant-table-cell-row-hover {
          background: #0f0f0f;
        }
      }
    }
  }
}

.transformNone {
  text-transform: none !important;
}

.ant-select-selector {
  width: 100%;
}

.light {
  .seat-map {
    #renderViewerSide + div {
      span.flex {
        div {
          // background-color: #999 !important;
          svg {
            color: #000;
            fill: #000;
            stroke: #000;
          }
        }
      }
    }
  }
}

.dark {
  .seat-map {
    #renderViewerSide + div {
      span.flex {
        div {
          background-color: #111 !important;
          svg {
            color: #fff;
            fill: #fff;
            stroke: #fff;
          }
        }
      }
    }
  }
}

.dark {
  .info_icon {
    width: 16px;
    height: 16px;
    // border: solid 1px rgb(146 59 255 / var(--tw-bg-opacity));
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: #fff;
    background-color: rgb(146 59 255 / var(--tw-bg-opacity));
    margin-left: 4px;
    opacity: 1 !important;
  }
}
.light {
  .info_icon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: rgb(2 132 199 / var(--tw-bg-opacity));
    color: #fff;
    margin-left: 4px;
    opacity: 1 !important;
  }
}
// .btnShadow{
//   border-radius: 4px;
//     background: #fff;
//     box-shadow: 5px 5px 10px #000,
//       -5px -5px 10px #ccc;
// }

.btnShadow {
  border-radius: 6px;
  background: linear-gradient(145deg, #d2d2d2, #ffffff);
  box-shadow: 5px 5px 10px #b1b0b0, -5px -5px 25px #fff;
  border: solid 1px #efefef;
}

.dark .btnShadow {
  border-radius: 6px;
  // background: linear-gradient(145deg, #000, #333);
  background: linear-gradient(
    145deg,
    rgb(var(--color-base-3)),
    rgb(var(--color-base-2))
  );
  box-shadow: 5px 5px 10px #000, -5px -5px 25px #333;
  border: solid 1px var(--color-border-1);
}
.ReactInputVerificationCode__item {
  border-radius: 10px !important;
  height: 65px !important;
  width: 65px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
}
.ReactInputVerificationCode__item.is-active {
  border: #0ea5e9 solid 1px;
  box-shadow: none !important;
}
.ReactInputVerificationCode__container {
  width: 100% !important;
}

.cstm_scroll {
  height: calc(100vh - 220px);
}
.ant-drawer {
  .gotix-drawer {
    .ant-drawer-close {
      width: auto !important;
      height: auto !important;
    }
  }
}
