.bannerImg {
  background-size: cover;
  background-image: url(/src/assets/images/banner/home-banner.png);
  background-repeat: no-repeat;
  min-height: 100vh;
  @include responsivemin(768) {
    min-height: 100vh;
  }
  @include responsivemin(1200) {
    background-size: 100% 100%;
  }
}

.upComing-Event-Card {
  background: linear-gradient(76.21deg, #000729 0%, #033d81 98.86%);
  border-radius: 0;
}

.tabMinWidth {
  min-width: 100px;
}

.cardOverlay {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}
.cardOverlay-50 {
  width: 100%;
  height: 0;
  padding-bottom: 52%;
}
