.TicketOverlay {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  //   background: hsla(0, 0%, 100%, 0.2);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: 10px 15px;
  border-radius: 999px;
  color: #fff;
  cursor: pointer;
  z-index: 3;
}
.arrowStep {
  transition: all 0.5s ease;
  &::after {
    transition: all 0.5s ease;
    content: '';
    position: absolute;
    top: 0;
    right: -29px;
    width: 58px;
    height: 58px;
    transform: scale(0.707) rotate(45deg);
    z-index: 1;
    border-radius: 0 10px 0 50px;
    border-left: 0;
    border-bottom: 0;
  }
}

// .ticket_font {
//   font-family: 'Blanka', sans-serif;
// }

// .btnTicket {
//   &::before,
//   &:after {
//     z-index: -1;
//     content: '';
//     position: absolute;
//     height: 1.2em;
//     width: 1em;
//     top: 1.4em;
//     border-radius: 50%;
//     border: 3px solid white;
//     box-shadow: 0 0 0 10em #102229;
//   }
//   &:before {
//     left: -0.7em;
//   }
//   &:after {
//     right: -0.7em;
//   }
//   &:after {
//     content: '>';
//     color: #da153e;
//     font-weight: bold;
//   }
// }

// table,
// #headlessui-portal-root {
//   word-break: break-all;
// }
