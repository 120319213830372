// @import 'bootstrap/dist/css/bootstrap.min.css';
// $enable-important-utilities: false; //this disables !important
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import './pages/dashboard_v2.scss';
.flex-wrap2 {
  flex-wrap: wrap;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
// Font poppins
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Predefined Classes
@import './mixins.scss';
@import './variable.scss';
@import './variable_v2.scss';
// @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Arvo&family=Bitter&family=Copse&family=Cutive&family=Lato:ital,wght@0,100;0,300;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lora:wght@700&family=Orbitron:wght@400;500;600;700;800;900&family=Sanchez&family=Scope+One&family=Slabo+27px&family=Trocchi&family=Vesper+Libre&display=swap');
// @import url('https://fonts.cdnfonts.com/css/blanka');
// Components
// @import './components/form.scss';
@import './components/button.scss';
@import './components/header.scss';
@import './components/flip_counter.scss';
@import './components/upcomingEvent.scss';
@import './components/modal.scss';
@import './components/page_loader.scss';
@import './components/event_ticket.scss';
@import './components/slider.scss';
@import './components/footer.scss';
@import './components/ticket_card.scss';
@import './components/stream_lounge.scss';
@import './components/tailwind_overide.scss';

@import './components/nft_card.scss';

// Pages
// @import './pages/theme.scss';
@import './global.scss';
@import './pages/auth.scss';
@import './pages/homepage.scss';
@import './pages/error-page.scss';
@import './pages/landing_page.scss';
@import './pages/privacy.scss';
@import './pages/live_stream.scss';
@import './pages/version3_addons.scss'; // New Design

@import './theme.scss';
// @import './pages/NewDesign';
// @import './pages/broker';
