// seperator content

.separator {
  &.separator-content {
    display: flex;
    align-items: center;
    border-bottom: 0;
    text-align: center;
    &::before,
    &::after {
      content: ' ';
      width: 50%;
      border-bottom: 1px solid $primary_color;
    }

    &::before {
      margin-right: 1.25rem;
    }

    &::after {
      margin-left: 1.25rem;
    }
  }
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-check {
  display: flex;
  gap: 0.5rem;

  .form-check-input {
    width: 17px;
    height: 17px;
    flex-shrink: 0;
  }
  .form-check-label {
    // line-height: 2;
    // margin-top: 0.25em;
    // line-height: 1.2;
    line-height: 1.8;
    // margin-top: 0.25em;
  }
}
.form-check-input,
.form-check-label {
  cursor: pointer;
}
.MuiDateTimePickerToolbar-dateContainer {
  .MuiButtonBase-root {
    &:first-child {
      &.MuiPickersToolbar-root {
        display: none !important;
      }
    }
    .MuiTypography-root {
      color: #323232 !important;
    }
  }
}
.MuiDateTimePickerToolbar-timeContainer {
  .MuiButtonBase-root {
    .MuiTypography-root {
      color: #323232 !important;
    }
  }
}
.MuiButtonBase-root {
  &.MuiPickersDay-root {
    &.Mui-selected {
      background-color: $primary_color;

      &:hover {
        background-color: $primary_color_hover;
      }
    }
  }
  &.MuiTab-textColorPrimary {
    &:hover {
      color: $primary_color_hover !important;
    }
  }
}

.mySelect {
  padding: 1rem 0.75rem;
  .form-floating > .form-control-plaintext:focus,
  .form-floating > .form-control-plaintext:not(:placeholder-shown),
  .form-floating > .form-control:focus,
  .form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }

  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }
}

// Dark Radio Button

.darkRadio {
  .ant-radio {
    &.ant-radio-checked {
      .ant-radio-inner {
        border-color: #923bff;
        background-color: #923bff;
      }
    }
  }
}

.leading-0 {
  .form-check-label {
    line-height: 1.3;
  }
}

// antd select

:where(.css-dev-only-do-not-override-190m0jy).ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 500 !important;
}
