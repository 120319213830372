$halfHeight: 0.52em;
$borderRadius: 0.15em;

.flip-countdown {
  text-align: center;
  perspective: 400px;
  margin: 0 auto;
  //   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //     sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .flip-countdown-piece {
    display: inline-block;
    // display: flex;
    // align-items: flex-end;
    margin: 0 0.22em;

    .flip-countdown-title {
      font-size: 2vw;
    }

    .flip-countdown-card {
      display: flex;
      column-gap: 2px;
      // letter-spacing: -8px;

      .flip-countdown-card-sec {
        display: block;
        position: relative;
        // margin: 0 0.04em;
        // padding-bottom: $halfHeight;
        font-size: 9vw;
        line-height: 0.95;
        border-radius: $borderRadius;
        // box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
        //   inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);

        .card__top,
        .card__bottom,
        .card__back::before,
        .card__back::after {
          display: block;
          //   height: $halfHeight;
          color: #fff;
          //   background: #222;
          background: transparent;
          // padding: 0 0.1em;
          border-radius: $borderRadius $borderRadius 0 0;
          transform-style: preserve-3d;
          transform: translateZ(0);
        }

        // .card__top {
        //   border-bottom: solid 1px #000;
        // }

        // .card__bottom {
        //   color: #fff;
        //   position: absolute;
        //   top: 50%;
        //   left: 0;
        //   //   background: #393939;
        //   background: transparent;
        //   border-radius: 0 0 $borderRadius $borderRadius;
        //   pointer-events: none;
        //   overflow: hidden;

        //   &::after {
        //     display: block;
        //     margin-top: -$halfHeight;
        //     content: attr(data-value);
        //   }
        // }

        // .card__back {
        //   position: absolute;
        //   top: 0;
        //   height: 100%;
        //   left: 0%;
        //   pointer-events: none;

        //   &::before {
        //     position: relative;
        //     content: attr(data-value);
        //     z-index: -1;
        //     overflow: hidden;
        //   }
        // }

        // &.flip {
        //   .card__back {
        //     .card__bottom {
        //       transform-origin: center top;
        //       animation-fill-mode: both;
        //       animation: flipBottom 0.6s cubic-bezier(0.15, 0.45, 0.28, 1);
        //     }

        //     &::before {
        //       animation: flipTop 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
        //       animation-fill-mode: both;
        //       transform-origin: center bottom;
        //     }
        //   }
        // }
      }
    }
  }

  .flip-countdown-card-divider {
    font-size: 2vw;
  }

  // Themes
  &.theme-light {
    .flip-countdown-piece {
      .flip-countdown-card {
        .flip-countdown-card-sec {
          .card__top,
          .card__bottom,
          .card__back::before,
          .card__back::after {
            color: #de4848;
            background: #f7f7f7;
          }

          .card__top {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }

          .card__bottom {
            color: #de4848;
            background: #ffffff;
          }
        }
      }
    }
  }

  // Sizes
  &.size-medium {
    .flip-countdown-piece {
      //   margin: 0 0.2em;

      .flip-countdown-title {
        font-size: 18px;
      }

      .flip-countdown-card {
        .flip-countdown-card-sec {
          //   margin: 0 0.025em;
          font-size: 35px;
        }
      }
    }
  }

  &.size-small {
    .flip-countdown-piece {
      margin: 0 0.15em;

      .flip-countdown-title {
        font-size: 14px;
      }

      .flip-countdown-card {
        .flip-countdown-card-sec {
          margin: 0 0.023em;
          font-size: 32px;
        }
      }
    }
  }

  &.size-extra-small {
    .flip-countdown-piece {
      // margin: 0 0.15em;
      margin: 0 0em;

      .flip-countdown-title {
        font-size: 10px;
      }

      .flip-countdown-card {
        // letter-spacing: -5px;
        min-width: 19px;
        .flip-countdown-card-sec {
          // margin: 0 0.025em;
          margin: 0 0em;
          font-size: 16px;
          // font-size: 1em;
          letter-spacing: -3px;
          // font-size: 14px;
        }
      }
    }
  }
}

@keyframes flipTop {
  0% {
    transform: rotateX(0deg);
    z-index: 2;
  }

  0%,
  99% {
    opacity: 0.99;
  }

  100% {
    transform: rotateX(-90deg);
    opacity: 0;
  }
}

@keyframes flipBottom {
  0%,
  50% {
    z-index: -1;
    transform: rotateX(90deg);
    opacity: 0;
  }

  51% {
    opacity: 0.99;
  }

  100% {
    opacity: 0.99;
    transform: rotateX(0deg);
    z-index: 5;
  }
}

// Flipcountdown new

._qWTcH {
  // --fcc-flip-duration: 0.7s;
  // --fcc-digit-block-width: 46px;
  // --fcc-digit-block-height: 80px;
  // --fcc-digit-font-size: 50px;
  // --fcc-label-font-size: 16px;
  // --fcc-label-color: #fff;
  // --fcc-background: #0f181a;
  // --fcc-digit-color: #fff;
  // --fcc-divider-color: hsla(0, 0%, 100%, 0.4);
  // --fcc-divider-height: 1px;
  // --fcc-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
  // --fcc-separator-size: 5px;
  // --fcc-separator-color: var(--fcc-digit-color);

  --fcc-flip-duration: 0.7s;
  --fcc-digit-block-width: 25px;
  --fcc-digit-block-height: 50px;
  --fcc-digit-font-size: 22px;
  --fcc-label-font-size: 12px;
  --fcc-label-color: #fff;
  --fcc-background: #182123;
  --fcc-digit-color: #fff;
  --fcc-divider-color: hsla(0, 0%, 18%, 0.4);
  --fcc-divider-height: 1px;
  --fcc-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
  --fcc-separator-size: 5px;
  --fcc-separator-color: var(--fcc-digit-color);
  font-family: inherit;
  font-size: var(--fcc-digit-font-size);
  color: var(--fcc-digit-color);
  line-height: 0;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: default;
  display: flex;
  align-items: center;

  @include responsivemin(576) {
    --fcc-digit-font-size: 45px;
    --fcc-digit-block-width: 42px;
    --fcc-digit-block-height: 80px;
    --fcc-label-font-size: 18px;
  }
}
._1tbqx ._3cpN7 {
  margin-bottom: calc(var(--fcc-label-font-size) * 2);
}
._3cpN7 ._2EBbg:not(:last-child) {
  margin-right: 4px;
}
._3cpN7 {
  position: relative;
  display: flex;
  align-items: center;
}
._2aOn7 {
  color: var(--fcc-label-color);
  line-height: 1;
  font-weight: 400;
  font-size: var(--fcc-label-font-size);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 150%);
}
._2EBbg {
  perspective: 200px;
  position: relative;
  width: var(--fcc-digit-block-width);
  height: var(--fcc-digit-block-height);
  box-shadow: var(--fcc-shadow);
  border-radius: 4px;
}
._106d2,
._SKh-V {
  position: absolute;
  width: 100%;
  height: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background: var(--fcc-background);

  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
}
._SKh-V {
  align-items: flex-end;
  top: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: var(--fcc-divider-height) solid var(--fcc-divider-color);
}
._106d2 {
  align-items: flex-start;
  bottom: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
._3luxx {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 50%;
  transform-style: preserve-3d;
  transform-origin: bottom;
  transform: rotateX(0);
}
._3luxx._2kgZC {
  transition: transform var(--fcc-flip-duration) ease-in-out;
  transform: rotateX(-180deg);
}
._2v-d4 {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  backface-visibility: hidden;
  background: var(--fcc-background);
}
._1GZQ7 {
  align-items: flex-end;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: var(--fcc-divider-height) solid var(--fcc-divider-color);
}
._3AVXa {
  align-items: flex-start;
  transform: rotateX(-180deg);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
._2hRXr {
  margin-left: 8px;
  margin-right: 8px;
  height: var(--fcc-digit-block-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
._1tbqx ._2hRXr {
  margin-bottom: calc(var(--fcc-label-font-size) * 2);
}
._2hRXr:after,
._2hRXr:before {
  content: '';
  width: var(--fcc-separator-size);
  height: var(--fcc-separator-size);
  border-radius: 50%;
  background-color: var(--fcc-separator-color);
}
._2hRXr:before {
  margin-bottom: var(--fcc-separator-size);
}
