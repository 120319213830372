// body {
//   font-family: 'Inter', sans-serif !important;
// }
body {
  font-size: 13px;
}
.dashboard_v2 {
  // font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  font-family: 'Inter', Helvetica, Arial, sans-serif !important;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.dataTables {
  &.dataTables_v2 {
    .ant-table-thead {
      // background: #f1f2f3;
      tr {
        .ant-table-cell {
          background: #f1f2f3;
          border-radius: 0 !important;
          padding-top: 0.5rem !important;
          padding-bottom: 0.5rem !important;
        }
      }
    }
    .ant-table-tbody {
      .dataTablesRow {
        .ant-table-cell {
          border-radius: 0 !important;
          &.ant-table-cell-row-hover {
            border-radius: 0 !important;
          }
        }
      }
    }
    .ant-table-expanded-row {
      .ant-table-cell {
        padding: 0;
      }
    }
  }
}
.expandedRowBg {
  .ant-table-expanded-row {
    .ant-table-cell {
      background-color: $white;
    }
    &:hover {
      .ant-table-cell {
        background-color: $white;
      }
    }
  }
}
.cls-1 {
  opacity: 0.1;
}
.cls-2 {
  fill: #55585e;
}
.cls-3 {
  opacity: 0.6;
}
.cls-4 {
  opacity: 0.5;
}
