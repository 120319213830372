.bg-gradientHeader {
  background: linear-gradient(76.21deg, #000729 0%, #033d81 98.86%);
  .eventBreadcrumbs {
    background: rgba(9, 8, 8, 0.3);
  }
}

.bg-noHeader {
  background-color: transparent;
  position: absolute;
  width: 100%;
  z-index: 99;

  @include responsivemin(768) {
    padding-top: 2rem;
  }
}
.hover_animate {
  transition: all 0.5s ease;
}
.unified_top_header {
  background-color: $topband-bg;
  color: $topband-font-color;
  border: 1px solid $sidebar-font-color;
}
