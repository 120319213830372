.banner_search_box {
  background-color: #a88e7e;
  // overflow: hidden;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  //   background-color: rgba(17, 25, 40, 0.75);
  // border-radius: 12px;
  // border: 1px solid rgba(255, 255, 255, 0.125);
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 0;
    border-radius: 1rem;
  }
  .just-search {
    // backdrop-filter: blur(16px) saturate(180%);
    // -webkit-backdrop-filter: blur(16px) saturate(180%);
    // background-color: rgba(255, 255, 255, 0.169);
    // border-radius: 12px;
    // background: #8d7769;
    // background-color: #a88ea5;
    border: 1px solid rgba(255, 255, 255, 0.125);
  }
}
.mobileMenuList {
  .ant-list-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.169);
  }
  .ant-spin-container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }
}
.ant-select,
.ant-select-selection-item {
  font-family: 'Poppins', sans-serif !important;
  .ant-select-selection-placeholder {
    color: rgb(157 171 190) !important;
  }
}
.my-search {
  background-color: #a88e7e;
  // overflow: hidden;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  &:focus,
  &:focus-visible,
  &:focus-within {
    // border: 0px solid #d4bcad;
    box-shadow: 1px 1px #d4bcad;
  }

  .ant-select {
    .ant-select-selection-item {
      color: $white !important;
    }
    .ant-select-selection-search-input,
    .ant-select-selection-placeholder {
      color: $white !important;
      &::placeholder {
        color: $white;
      }
    }
    .ant-select-clear {
      background-color: transparent;
    }
  }
}
.custom-range-picker {
  .ant-picker-input {
    color: $white;
    input {
      color: $white;
      &::placeholder {
        color: #737373;
      }
    }
  }
  .ant-picker-suffix {
    color: $white !important;
  }
  .ant-picker-clear {
    background-color: transparent !important;
  }
  .swap-right {
    color: $white !important;
  }
}
.custom-select {
  background-color: rgb(38 38 38/0.5);
  -webkit-backdrop-filter: blur(64px);
  backdrop-filter: blur(64px);
  .ant-select-item {
    color: $white;
    &.ant-select-item-option-selected {
      background-color: rgb(38 38 38/0.5);
      color: $white;
    }
  }
}
.dropdown_landing {
  // background-color: #923bff;
  background-color: #a88e7e;
  // overflow: hidden;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  // border-top-left-radius: 0.5rem;
  // border-bottom-left-radius: 0.5rem;
  color: $white;

  &:focus,
  &:focus-visible,
  &:focus-within {
    // border: 0px solid #d4bcad;
    box-shadow: 1px 1px #d4bcad;
    outline: 0;
    // border-radius: 0;
  }
}
.dropdown-menu {
  z-index: 11;
  background-color: #212121dd;
  background-color: rgb(36 36 36);
  // overflow: hidden;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);

  max-width: 15px;
  width: 100%;
  min-width: 150px !important;
  max-width: 150px;

  // &.show {
  //   display: flex;
  //   justify-content: center;
  //   flex-direction: column;
  // }
  .dropdown-item {
    color: $white;

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: rgb(23 23 23);
    }
  }
}
.noinput {
  border: 0;
  outline: 0;
  border-radius: 0;
  color: $white;

  &:focus,
  &:focus-visible,
  &:focus-within {
    border: 0;
    outline: 0;
    border-radius: 0;
    color: $white;
  }
}

.card_box {
  //   position: relative;
  //   width: 50%;
  //   float: right;

  //   .overlay {
  //     position: absolute;
  //     bottom: 0;
  //     left: 0;
  //     background-color: #008cba;
  //     overflow: hidden;
  //     width: 0;
  //     height: 100%;
  //     transition: 0.5s ease;
  //   }

  //   &:hover .overlay {
  //     width: 100%;
  //     left: -100%;
  //   }

  .image {
    display: block;
    width: 100%;
    height: auto;
  }
  transition: opacity overflow width 2s ease;

  .overlay {
    position: absolute;
    // inset: 0;
    background-color: #1d1d1d;
    overflow: hidden;
    width: 100%;
    // width: 0;
    height: 100%;
    inset: 0;
    left: 0px;
    transition: opacity overflow width 0.5s ease;
    transition: opacity overflow width 2s ease;
    z-index: 0;
    opacity: 0;
  }
  .slide_card {
    z-index: 1;
  }
  // &.swiper-slide {
  //   transition: width 0.5s ease;
  // }
  &:hover {
    .overlay {
      width: 100%;
      //   right: -98%;
      left: -15px;
      position: relative;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      opacity: 1;
    }
    &.swiper-slide {
      width: 100% !important;
      @include responsivemin(576) {
        width: 100% !important;
      }
      @include responsivemin(767) {
        width: 64% !important;
      }
      @include responsivemin(1200) {
        width: 47.8% !important;
      }
      @include responsivemin(1400) {
        width: 48.1% !important;
      }
      @include responsivemin(1920) {
        width: 38.2% !important;
      }
    }
    .slide_card {
      width: 100% !important;
    }
  }

  .text {
    white-space: nowrap;
    color: white;
    font-size: 20px;
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
}

.event_card_box {
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(5px) saturate(180%);
  -webkit-backdrop-filter: blur(5px) saturate(180%);
}
.dark .event_card_box {
  background-color: rgba(82, 82, 82, 0.4);
  backdrop-filter: blur(5px) saturate(180%);
  -webkit-backdrop-filter: blur(5px) saturate(180%);
}

.card_rounded_box {
  .overlay2 {
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    // background-color: #18182c;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.5s ease;
    border-top-left-radius: 99999px;
    border-top-right-radius: 99999px;
    z-index: 9;
    // backdrop-filter: blur(16px) saturate(180%);
    // -webkit-backdrop-filter: blur(16px) saturate(180%);
    //   background-color: rgba(17, 25, 40, 0.75);
    // border: 1px solid rgba(255, 255, 255, 0.125);
  }
  &:hover {
    .overlay2 {
      height: 60%;
    }
  }
}
.card_box_event {
  z-index: 0;
  .event_overlay {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    // background-color: #18182c;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.5s ease;
    // border-top-left-radius: 99999px;
    // border-top-right-radius: 99999px;
    z-index: 9;
    // backdrop-filter: blur(16px) saturate(180%);
    // -webkit-backdrop-filter: blur(16px) saturate(180%);
    //   background-color: rgba(17, 25, 40, 0.75);
    // border: 1px solid rgba(255, 255, 255, 0.125);
  }
  .opacity_onhover {
    transition: 0.2s ease;
  }
  &:hover {
    .opacity_onhover {
      transition: 0.5s ease;
      opacity: 0;
    }
    .event_overlay {
      height: 100%;
    }
  }
}

.card_shadow {
  // box-shadow: rgba(79, 17, 150, 0.1) 0px 4px 12px;
  // box-shadow: rgb(56 56 56 / 37%) 4px 4px, rgb(105 105 105 / 30%) 8px 8px,
  //   rgb(28 22 36 / 22%) 12px 12px, rgba(0, 0, 0, 0.1) 1px 10px,
  //   rgba(0, 0, 0, 0.05) 0 0;

  border: 2px solid #bbbbbb29 !important;
}

.heading_bar {
  border-radius: 50%;

  line {
    stroke: chocolate;
    fill: aqua;
    border-radius: 50%;
    transition: 0.5s all ease;
    -webkit-animation: colorchange 20s infinite;
    -moz-animation: colorchange 20s infinite;
    -o-animation: colorchange 20s infinite;
    -ms-animation: colorchange 20s infinite;
    animation: colorchange 20s infinite;
  }
  @keyframes colorchange {
    0% {
      stroke: #d35400;
    }
    10% {
      stroke: #8e44ad;
    }
    20% {
      stroke: #1abc9c;
    }
    30% {
      stroke: #d35400;
    }
    40% {
      stroke: white;
    }
    50% {
      stroke: #3e76ae;
    }
    60% {
      stroke: rgb(153, 153, 253);
    }
    70% {
      stroke: #2980b9;
    }
    80% {
      stroke: #f1c40f;
    }
    90% {
      stroke: #2980b9;
    }
    100% {
      stroke: pink;
    }
  }
}

// dark dropdown

.dark_dropdown {
  .ant-dropdown-menu {
    background-color: rgba(35, 35, 35, 0.78);
    backdrop-filter: blur(5px) saturate(180%);
    -webkit-backdrop-filter: blur(5px) saturate(180%);
    .ant-dropdown-menu-item {
      &.ant-dropdown-menu-item-active {
        background-color: rgba(61, 61, 61, 0.917);
        backdrop-filter: blur(5px) saturate(180%);
        -webkit-backdrop-filter: blur(5px) saturate(180%);
      }
    }
  }
}

.btn-epic {
  position: relative;
  margin: auto;
  width: 100%;
  // max-width: 260px;
  height: 40px;
  transform: translate3d(0px, 0%, 0px);
  font-weight: 600;
  transition-delay: 0.6s;
  overflow: hidden;
  outline: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(111 44 195);
    border-radius: 50% 50% 0 0;
    transform: translateY(100%) scaleY(0.5);
    transition: all 0.6s ease;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $purpleDark;
    border-radius: 0;
    transform: translateY(0) scaleY(1);
    transition: all 0.6s ease;
  }
  div {
    position: relative;
    width: 100%;
    text-transform: uppercase;
    height: 24px;
    overflow: hidden;
  }
  span {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    transition: transform 0.5s ease;
    &:first-child {
      color: #fff;
      transform: translateY(24px);
    }
    &:last-child {
      color: #fff;
      transform: translateY(0);
    }
  }
  &:hover {
    background-color: rgb(111 44 195);
    transition: background-color 0.2s linear;
    transition-delay: 0.6s;
    &:after {
      border-radius: 0 0 50% 50%;
      transform: translateY(-100%) scaleY(0.5);
      transition-delay: 0;
    }
    &:before {
      border-radius: 0;
      transform: translateY(0) scaleY(1);
      transition-delay: 0;
    }
    span {
      &:first-child {
        transform: translateY(0);
      }
      &:last-child {
        transform: translateY(-24px);
      }
    }
  }

  &.btn-epic2 {
    &:before {
      background-color: rgb(64 64 64 / 0.75);
    }
    &:after {
      background-color: rgb(38 38 38);
    }
    &:hover {
      background-color: rgb(64 64 64 / 0.75);
    }
  }
}

// Date Range Picker

.static-range-picker {
  .rdrDefinedRangesWrapper {
    display: none !important;
  }
  &.modalPicker {
    .rdrMonthsHorizontal {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }
}

.date_range_picker_popover {
  // background-color: rgba(35, 35, 35, 0.78);
  // backdrop-filter: blur(5px) saturate(180%);
  // -webkit-backdrop-filter: blur(5px) saturate(180%);
  .ant-popover-inner {
    background-color: rgba(35, 35, 35, 0.78);
    backdrop-filter: blur(5px) saturate(180%);
    -webkit-backdrop-filter: blur(5px) saturate(180%);
  }
  .static-range-picker {
    .rdrDateRangeWrapper,
    .rdrCalendarWrapper,
    .rdrDateDisplayWrapper {
      background-color: rgba(35, 35, 35, 0.78);
      backdrop-filter: blur(5px) saturate(180%);
      -webkit-backdrop-filter: blur(5px) saturate(180%);
      background-color: transparent;
      // .rdrMonthAndYearWrapper {
      //   padding-top: 0;
      //   position: absolute;
      //   width: 100%;
      //   top: -12px;
      //   left: -20px;
      //   margin-right: -40px;
      //   .rdrNextButton {
      //     margin-right: -28px;
      //   }
      // }
      .rdrDayNumber span {
        color: #ffffff;
      }
      .rdrDay {
        span {
          color: #ffffff;
        }
      }
      .rdrDayPassive .rdrDayNumber span {
        color: #696969;
      }

      .rdrStartEdge,
      .rdrEndEdge,
      .rdrInRange {
        color: $purpleDark !important;
      }
      .rdrDayHovered,
      .rdrDayStartPreview,
      .rdrDayEndPreview,
      .rdrDayInPreview {
        border-color: $purpleDark !important;
      }
      .rdrDayToday .rdrDayNumber span {
        &::after {
          background-color: $purpleDark;
        }
      }
      .rdrMonthName {
        color: $white;
      }
      .rdrMonthAndYearPickers {
        // display: none;
        .rdrMonthPicker,
        .rdrYearPicker {
          background-color: rgba(0, 0, 0, 0.78);
          backdrop-filter: blur(5px) saturate(180%);
          -webkit-backdrop-filter: blur(5px) saturate(180%);
          border-radius: 5px;
          select {
            color: $white;
            background-color: rgba(99, 99, 99, 0.78);
            backdrop-filter: blur(5px) saturate(180%);
            -webkit-backdrop-filter: blur(5px) saturate(180%);

            &::selection {
              background-color: $purpleDark;
            }
            &::-webkit-scrollbar {
              width: 7px !important;
              height: 4px !important;
              cursor: pointer !important;
            }

            &::-webkit-scrollbar-track {
              box-shadow: inset 0 0 6px rgba(13, 13, 13, 0.807) !important;
              border-radius: 20px;
              // margin-top: 5px;
              // margin-bottom: 5px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: $purpleDark;
              // outline: 1px solid rgba(112, 128, 144, 0.212);
              border-radius: 20px;
            }
            &:hover {
              background-color: rgba(99, 99, 99, 0.78);
            }
            option {
              background-color: rgba(0, 0, 0, 0.78);
              &:hover {
                background-color: rgba(99, 99, 99, 0.78);
              }
            }
          }
        }
      }
    }
  }
}
// Search Bar

.dark .mysearchMenu {
  .ant-list-header {
    position: sticky;
    top: 0;
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(5px) saturate(180%);
    -webkit-backdrop-filter: blur(5px) saturate(180%);
    z-index: 2;
    transition: all 0.3s ease;
  }
}
.mysearchMenu {
  .ant-list-header {
    position: sticky;
    top: 0;
    background-color: #efefef;
    backdrop-filter: blur(5px) saturate(180%);
    -webkit-backdrop-filter: blur(5px) saturate(180%);
    z-index: 2;
    transition: all 0.3s ease;
  }
}
// filter Range Slider

.dark .dark-sidebar {
  .ant-checkbox-wrapper {
    .ant-checkbox {
      .ant-checkbox-inner {
        background-color: #262222;
        border-color: #8e44ad !important;
      }
      &:hover {
        .ant-checkbox-inner {
          border-color: #62237d !important;
        }
      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: #8e44ad;
          border-color: #62237d !important;
        }
        &:hover {
          .ant-checkbox-inner {
            background-color: #8e44ad !important;
            border-color: #8e44ad !important;
          }
        }
        &::after {
          border-color: #8e44ad !important;
        }
      }
      &::after {
        border-color: #62237d !important;
      }
    }
    .scrollCss {
      &::-webkit-scrollbar-thumb {
        background-color: $purpleDark;
      }
      &::-webkit-scrollbar {
        width: 3px !important;
        height: 3px !important;
        cursor: pointer !important;
      }
    }
    &:hover {
      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: #8e44ad !important;
          border-color: #62237d !important;
        }
      }
    }
  }
}
// .dark-sidebar {
//   .ant-checkbox-wrapper {
//     .ant-checkbox {
//       .ant-checkbox-inner {
//         background-color: #ddd;
//         border-color: #ccc !important;
//       }

//       &:hover {
//         .ant-checkbox-inner {
//           border-color: #62237d !important;
//         }
//       }

//       &.ant-checkbox-checked {
//         .ant-checkbox-inner {
//           background-color: #8e44ad;
//           border-color: #62237d !important;
//         }

//         &:hover {
//           .ant-checkbox-inner {
//             background-color: #8e44ad !important;
//             border-color: #8e44ad !important;
//           }
//         }

//         &::after {
//           border-color: #8e44ad !important;
//         }
//       }

//       &::after {
//         border-color: #62237d !important;
//       }
//     }

//     .scrollCss {
//       &::-webkit-scrollbar-thumb {
//         background-color: $purpleDark;
//       }

//       &::-webkit-scrollbar {
//         width: 3px !important;
//         height: 3px !important;
//         cursor: pointer !important;
//       }
//     }

//     &:hover {
//       .ant-checkbox-checked {
//         .ant-checkbox-inner {
//           background-color: #8e44ad !important;
//           border-color: #62237d !important;
//         }
//       }
//     }
//   }
// }
.filter-range-slider {
  .ant-slider-handle {
    &::before {
      border-radius: 50%;
      background-color: transparent;
      overflow: hidden;
    }
    &::after {
      box-shadow: 0 0 0 2px $purpleDark;
      background-color: rgb(40, 34, 47);
    }

    &:hover,
    &:active,
    &:focus {
      &::after {
        box-shadow: 0 0 0 2px $purpleDark;
      }
      &::before {
        border-radius: 50%;
        background-color: transparent;
        overflow: hidden;
      }
    }
  }
  .ant-slider:hover .ant-slider-handle {
    &::after {
      box-shadow: 0 0 0 2px $purpleDark !important;
    }
  }
}

// Antd Date Picker

.antdDatepicker {
  font-family: 'Poppins', sans-serif !important;
  .ant-picker-input {
    input {
      color: $white;

      &::placeholder {
        color: rgb(100 116 139);
      }
    }
    .ant-picker-suffix {
      .anticon {
        color: $white;
      }
    }
    .ant-picker-clear {
      background-color: transparent;
      color: $white;
    }
  }
}

.date-picker-antd {
  .ant-picker-panel-container {
    background-color: rgba(35, 35, 35, 0.78);
    backdrop-filter: blur(5px) saturate(180%);
    -webkit-backdrop-filter: blur(5px) saturate(180%);
    .ant-picker-header {
      color: $white;
      border-color: $table_row_color;
      button {
        color: $white;
      }
      .ant-picker-header-view {
        button {
          color: $white;
          &:hover {
            color: $purpleDark;
          }
        }
      }
    }
    .ant-picker-footer {
      border-color: $table_row_color;
      .ant-picker-today-btn {
        color: $white;
        &:hover {
          color: $purpleDark;
        }
      }
    }
    .ant-picker-content {
      thead {
        tr {
          th {
            color: $white;
          }
        }
      }

      .ant-picker-cell {
        color: #323232;
        &.ant-picker-cell-in-view {
          color: $white;

          &:hover {
            .ant-picker-cell-inner {
              background-color: rgba(0, 0, 0, 0.78) !important;
            }
          }

          &.ant-picker-cell-today {
            .ant-picker-cell-inner {
              &::before {
                border-color: $purpleDark;
              }
            }
          }
          &.ant-picker-cell-selected {
            .ant-picker-cell-inner {
              background-color: $purpleDark !important;
            }
          }
        }
      }
    }
  }
}

// Skeleton

.skeletonNftGotix {
  .skeletonNftGotix {
    background-color: #f0f0f0 !important;
    height: 100% !important;
  }
}
.dark .skeletonNftGotix {
  .skeletonNftGotix {
    background-color: #212121 !important;
    height: 100% !important;
  }
}
.skeletonNftGotixAvatar {
  display: flex;
  flex-direction: column;
  align-items: start;
  &.avatarCenter {
    align-items: center;
  }

  .ant-skeleton-avatar {
    background-color: #212121 !important;
    width: 70px !important;
    height: 70px !important;
  }
  .ant-skeleton-title,
  .ant-skeleton-paragraph li {
    background-color: #212121 !important;
    width: 100% !important;
  }
  .ant-skeleton-content,
  .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-block-start: 13px;
  }
  .ant-skeleton-header {
    padding-inline-end: 0 !important;
    .ant-skeleton-avatar-circle {
      border-radius: 10px !important;
    }
  }
}

// Skeleton

// Color Picker

.colorPickBox {
  box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.25);
  background-image: conic-gradient(
    rgba(0, 0, 0, 0.06) 0 25%,
    transparent 0 50%,
    rgba(0, 0, 0, 0.06) 0 75%,
    transparent 0
  );
  background-size: 50% 50%;
}

// Search page
.datePickerClass {
  .materialui-daterange-picker-MuiPaper-elevation5-15 {
    box-shadow: none !important;
  }
  .materialui-daterange-picker-MuiGrid-root-35 {
    flex-wrap: wrap !important;
  }
}
.ant-pagination-options {
  .ant-select {
    min-height: 32px !important;
  }
}
// Resale Event Detail Card

.resaleTable {
  border-radius: 10px;
  overflow: hidden;
  .ant-table {
    background-color: #0000005f;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      &::after {
        inset-inline-start: 22%;
      }
    }
  }
  .ant-table-thead {
    background-color: #080808 !important;
    .ant-table-cell {
      background-color: transparent;
      border-color: #ddd;
      &::before {
        background-color: #ddd !important;
      }
      &:nth-last-child(2) {
        &::before {
          display: none;
        }
      }
      // &:first-child {
      //   border-top-left-radius: 20px !important;
      //   border-bottom-left-radius: 20px !important;
      // }
      // &:last-child {
      //   border-top-right-radius: 20px !important;
      //   border-bottom-right-radius: 20px !important;
      // }
    }
  }
  .resaleTableRow {
    .ant-table-cell {
      border-color: #3e3e3e;
    }
    .ant-table-cell-row-hover {
      background-color: #1d1d1d !important;
    }
  }
  .ant-table-measure-row {
    height: 8px !important;
  }
  .ant-table-cell-scrollbar {
    display: none !important;
  }
  .ant-table-body,
  .ant-table-content {
    touch-action: manipulation;
    // overflow: auto!important;
    &::-webkit-scrollbar {
      width: 3px !important;
      height: 3px !important;
      cursor: pointer !important;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgb(2, 132, 199, 0.3) !important;
      border-radius: 20px;
      // margin-top: 5px;
      // margin-bottom: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $purpleDark;
      // outline: 1px solid rgba(112, 128, 144, 0.212);
      border-radius: 20px;
    }
    .ant-table-placeholder {
      background-color: transparent !important;
      .ant-table-expanded-row-fixed {
        padding: 0 !important;
      }
      &:hover {
        background-color: transparent !important;
      }
    }
  }
  // .resaleTableRow {
  //   .ant-table-cell {
  //     &:first-child {
  //       border-top-left-radius: 20px !important;
  //       border-bottom-left-radius: 20px !important;
  //     }
  //     &:last-child {
  //       border-top-right-radius: 20px !important;
  //       border-bottom-right-radius: 20px !important;
  //     }
  //     &.ant-table-cell-row-hover {
  //       &:first-child {
  //         border-top-left-radius: 20px !important;
  //         border-bottom-left-radius: 20px !important;
  //       }
  //       &:last-child {
  //         border-top-right-radius: 20px !important;
  //         border-bottom-right-radius: 20px !important;
  //       }
  //     }
  //   }
  // }
  &.removeRounded {
    .dataTablesRow {
      .ant-table-cell {
        border-width: 0px !important;
        background-color: #080808 !important;
      }
      .ant-table-cell-row-hover {
        background-color: #1d1d1d !important;
      }
    }
  }

  .dark .ticketConfirmTable {
    table {
      border-spacing: 0 !important;
    }
    border-radius: 0;
    .ant-table-cell {
      background-color: transparent !important;
    }
    .ant-table-thead {
      background-color: #000 !important;
      .ant-table-cell {
        border-radius: 0 !important;
      }
    }
    .ant-table-cell {
      border-radius: 0 !important;
    }
    .dataTablesRow {
      .ant-table-cell {
        border-radius: 0 !important;
        border-color: rgb(64 64 64) !important;
        overflow: unset !important;
        &.ant-table-cell-row-hover {
          border-radius: 0 !important;
        }
      }
    }
    .ant-table-expanded-row {
      .ant-table-cell {
        border-color: rgb(64 64 64) !important;
      }
    }
    .ant-table-row-expand-icon {
      background: rgb(64 64 64) !important;
      border-color: rgb(64 64 64) !important;
      color: $white !important;
    }
  }
  &.ticketConfirmTable {
    table {
      border-spacing: 0 !important;
    }

    border-radius: 0;

    .ant-table-cell {
      background-color: transparent !important;
    }

    .ant-table-thead {
      background-color: #fff !important;

      .ant-table-cell {
        border-radius: 0 !important;
        color: #000;
      }
    }

    .ant-table-cell {
      border-radius: 0 !important;
    }

    .dataTablesRow {
      .ant-table-cell {
        border-radius: 0 !important;
        border-color: #ddd !important;
        overflow: unset !important;
        background-color: #fff !important;

        &.ant-table-cell-row-hover {
          border-radius: 0 !important;
        }
      }
    }

    .ant-table-expanded-row {
      .ant-table-cell {
        border-color: #ddd !important;
      }
    }

    .ant-table-row-expand-icon {
      background: rgb(64 64 64) !important;
      border-color: rgb(64 64 64) !important;
      color: $white !important;
    }
  }
  .ant-pagination {
    margin-right: 16px !important;
    .ant-pagination-item {
      border-color: #171717 !important;
      background-color: #171717 !important;
      color: $white !important;
      &:hover {
        background-color: #2e2e2e !important;
      }
      &.ant-pagination-item-active {
        border-color: $purpleDark !important;
        background-color: $purpleDark !important;
        color: $white !important;
      }
    }
    .ant-pagination-next,
    .ant-pagination-prev {
      border-color: #171717 !important;
      background-color: #171717 !important;
      color: $white !important;
      &:hover {
        background-color: #2e2e2e !important;
      }
      .anticon {
        color: $white !important;
      }
      &.ant-pagination-disabled {
        opacity: 0.5;
      }
    }
  }
  .ant-pagination-options {
    .ant-select {
      min-height: 32px !important;
      .ant-select-arrow {
        color: $white !important;
      }
      .ant-select-selector {
        border-color: rgb(var(--color-border-2)) !important;
        background-color: #171717 !important;
        color: $white !important;
        &::after {
          color: $white !important;
        }
        .ant-select-selection-item {
          color: $white !important;
        }
      }
      .ant-select-dropdown {
        background-color: #171717 !important;
        .ant-select-item {
          color: $white !important;
          &.ant-select-item-option-selected {
            background-color: #2e2e2e !important;
          }
        }
      }
    }
  }
}

.dark .resaleTable {
  border-radius: 10px;
  overflow: hidden;
  .ant-table {
    background-color: #0000005f;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      &::after {
        inset-inline-start: 22%;
      }
    }
  }
  .ant-table-thead {
    background-color: #080808 !important;
    .ant-table-cell {
      background-color: transparent;
      border-color: #3e3e3e;
      &::before {
        background-color: #3e3e3e !important;
      }
      &:nth-last-child(2) {
        &::before {
          display: none;
        }
      }
      // &:first-child {
      //   border-top-left-radius: 20px !important;
      //   border-bottom-left-radius: 20px !important;
      // }
      // &:last-child {
      //   border-top-right-radius: 20px !important;
      //   border-bottom-right-radius: 20px !important;
      // }
    }
  }
  .resaleTableRow {
    .ant-table-cell {
      border-color: #3e3e3e;
    }
    .ant-table-cell-row-hover {
      background-color: #1d1d1d !important;
    }
  }
  .ant-table-measure-row {
    height: 8px !important;
  }
  .ant-table-cell-scrollbar {
    display: none !important;
  }
  .ant-table-body,
  .ant-table-content {
    touch-action: manipulation;
    // overflow: auto!important;
    &::-webkit-scrollbar {
      width: 3px !important;
      height: 3px !important;
      cursor: pointer !important;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgb(2, 132, 199, 0.3) !important;
      border-radius: 20px;
      // margin-top: 5px;
      // margin-bottom: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $purpleDark;
      // outline: 1px solid rgba(112, 128, 144, 0.212);
      border-radius: 20px;
    }
    .ant-table-placeholder {
      background-color: transparent !important;
      .ant-table-expanded-row-fixed {
        padding: 0 !important;
      }
      &:hover {
        background-color: transparent !important;
      }
    }
  }
  // .resaleTableRow {
  //   .ant-table-cell {
  //     &:first-child {
  //       border-top-left-radius: 20px !important;
  //       border-bottom-left-radius: 20px !important;
  //     }
  //     &:last-child {
  //       border-top-right-radius: 20px !important;
  //       border-bottom-right-radius: 20px !important;
  //     }
  //     &.ant-table-cell-row-hover {
  //       &:first-child {
  //         border-top-left-radius: 20px !important;
  //         border-bottom-left-radius: 20px !important;
  //       }
  //       &:last-child {
  //         border-top-right-radius: 20px !important;
  //         border-bottom-right-radius: 20px !important;
  //       }
  //     }
  //   }
  // }
  &.removeRounded {
    .dataTablesRow {
      .ant-table-cell {
        border-width: 0px !important;
        background-color: #080808 !important;
      }
      .ant-table-cell-row-hover {
        background-color: #1d1d1d !important;
      }
    }
  }

  .dark .ticketConfirmTable {
    table {
      border-spacing: 0 !important;
    }
    border-radius: 0;
    .ant-table-cell {
      background-color: transparent !important;
    }
    .ant-table-thead {
      background-color: #000 !important;
      .ant-table-cell {
        border-radius: 0 !important;
      }
    }
    .ant-table-cell {
      border-radius: 0 !important;
    }
    .dataTablesRow {
      .ant-table-cell {
        border-radius: 0 !important;
        border-color: rgb(64 64 64) !important;
        overflow: unset !important;
        &.ant-table-cell-row-hover {
          border-radius: 0 !important;
        }
      }
    }
    .ant-table-expanded-row {
      .ant-table-cell {
        border-color: rgb(64 64 64) !important;
      }
    }
    .ant-table-row-expand-icon {
      background: rgb(64 64 64) !important;
      border-color: rgb(64 64 64) !important;
      color: $white !important;
    }
  }
  &.ticketConfirmTable {
    table {
      border-spacing: 0 !important;
    }

    border-radius: 0;

    .ant-table-cell {
      background-color: transparent !important;
    }

    .ant-table-thead {
      background-color: #333 !important;

      .ant-table-cell {
        border-radius: 0 !important;
        color: #000;
      }
    }

    .ant-table-cell {
      border-radius: 0 !important;
    }

    .dataTablesRow {
      .ant-table-cell {
        border-radius: 0 !important;
        border-color: #555 !important;
        overflow: unset !important;
        background-color: #222 !important;

        &.ant-table-cell-row-hover {
          border-radius: 0 !important;
        }
      }
    }

    .ant-table-expanded-row {
      .ant-table-cell {
        border-color: #ddd !important;
      }
    }

    .ant-table-row-expand-icon {
      background: rgb(64 64 64) !important;
      border-color: rgb(64 64 64) !important;
      color: $white !important;
    }
  }
  .ant-pagination {
    margin-right: 16px !important;
    .ant-pagination-item {
      border-color: #171717 !important;
      background-color: #171717 !important;
      color: $white !important;
      &:hover {
        background-color: #2e2e2e !important;
      }
      &.ant-pagination-item-active {
        border-color: $purpleDark !important;
        background-color: $purpleDark !important;
        color: $white !important;
      }
    }
    .ant-pagination-next,
    .ant-pagination-prev {
      border-color: #171717 !important;
      background-color: #171717 !important;
      color: $white !important;
      &:hover {
        background-color: #2e2e2e !important;
      }
      .anticon {
        color: $white !important;
      }
      &.ant-pagination-disabled {
        opacity: 0.5;
      }
    }
  }
  .ant-pagination-options {
    .ant-select {
      .ant-select-arrow {
        color: $white !important;
      }
      .ant-select-selector {
        border-color: rgb(var(--color-border-2)) !important;
        background-color: #171717 !important;
        color: $white !important;
        &::after {
          color: $white !important;
        }
        .ant-select-selection-item {
          color: $white !important;
        }
      }
      .ant-select-dropdown {
        background-color: #171717 !important;
        .ant-select-item {
          color: $white !important;
          &.ant-select-item-option-selected {
            background-color: #2e2e2e !important;
          }
        }
      }
    }
  }
}

.ant-table {
  .ant-table-placeholder {
    .ant-table-cell {
      padding: 0 !important;
      border: 0px !important;
    }
  }
}

.removeRounded {
  border-radius: 0px;
}

.removeLastBorder {
  .antd-table {
    .resaleTableRow {
      &:last-child {
        td {
          border-bottom-width: 0 !important;
        }
      }
    }
  }
}
