html {
  // transition: width 0.5s ease;
  // padding: 0px !important;
}
.ant-drawer,
.ant-form-item-explain-error {
  font-family: 'Inter', Helvetica, Arial, sans-serif !important;
  .ant-table,
  .ant-table-cell {
    font-family: 'Inter', Helvetica, Arial, sans-serif !important;
  }
}
.inter-font {
  font-family: 'Inter', Helvetica, Arial, sans-serif !important;
}
.ant-form-item-explain-error {
  font-size: 12px !important;
}
body {
  padding: 0;
  margin: 0;
  font-size: 13px;
  font-family: 'Poppins', sans-serif !important;
  // font-family: 'Inter', Helvetica, Arial, sans-serif !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
  touch-action: manipulation;
  .custom_container {
    font-family: 'Inter', Helvetica, Arial, sans-serif !important;
    .ant-drawer,
    .ant-table {
      font-family: 'Inter', Helvetica, Arial, sans-serif !important;
    }
  }
  // &::-webkit-scrollbar {
  //   width: 10px !important;
  //   height: 4px !important;
  //   cursor: pointer !important;
  //   // backdrop-filter: blur(16px) saturate(180%);
  //   // -webkit-backdrop-filter: blur(16px) saturate(180%);
  //   background-color: rgb(68, 68, 68);
  // }

  // &::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 6px rgb(2, 132, 199, 0.3) !important;
  //   border-radius: 20px;
  //   // margin-top: 5px;
  //   // margin-bottom: 5px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   // background-color: $primary_color;
  //   // outline: 1px solid rgba(112, 128, 144, 0.212);
  //   border-radius: 20px;
  //   backdrop-filter: blur(16px) saturate(180%);
  //   -webkit-backdrop-filter: blur(16px) saturate(180%);
  //   background-color: rgb(33, 33, 33);
  // }
}
@include responsivemin(1200) {
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 33.3334%;
  }
}
@include responsivemin(1400) {
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 25%;
  }
}
@include responsivemin(1600) {
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 25%;
  }
}
// .backdrop-blur-lg {
//   --tw-backdrop-blur: blur(16px);
//   -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
//     var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
//     var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
//     var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
//     var(--tw-backdrop-sepia);
//   backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
//     var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
//     var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
//     var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
//     var(--tw-backdrop-sepia);
// }
.min_height_10 {
  min-height: 10rem;
}
.custom_container {
  .container {
    @include responsivemin(1200) {
      max-width: 1300px;
    }
    @include responsivemin(1400) {
      max-width: 1500px;
    }
    @include responsivemin(1600) {
      max-width: 1570px;
    }
    @include responsivemin(1700) {
      max-width: 1920px;
      // max-width: 95%;
    }
    @include responsivemin(1900) {
      // max-width: 1850px;
      max-width: 97%;
    }
    // @include responsivemin(2500) {
    //   max-width: 90%;
    // }

    // @include responsivemin(3750) {
    //   max-width: 3500px;
    // }
    // @media screen and (min-width: 2560px) {
    //   max-width: 2500px;
    // }
  }
}

.table_width {
  max-width: 84vw;
  // width: 100%;
  @include responsivemin(768) {
    max-width: 90vw;
  }
  @include responsivemin(1400) {
    max-width: 100%;
  }
}

.autoWidth,
.autoWidthHeight {
  .ant-table-body {
    overflow-y: auto !important;
  }
}
.textMuted {
  color: $muted;
}

.textPrimary {
  color: $primary_color;
}

.textSucess {
  color: $theme_green;
}
.border-1 {
  border-width: 1px;
}
.border-b-1 {
  border-bottom-width: 1px;
}
.bgPrimary {
  background-color: $primary_color;
}
.btn-link {
  color: $primary_color !important;
  &:hover,
  &:focus {
    color: $primary_color_hover !important;
  }
}
.borderColor {
  border-color: $border_ring !important;
}

/* Border Start*/

.artcDivider {
  border-bottom: 1px solid var(--color-border-1);
}
.artcDividervertical {
  border-right: 1px solid var(--color-border-1);
  width: max-content !important;
}
.Customdivider {
  z-index: 1;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    border-right: 2px dashed;
    height: 100%;
    z-index: 0;
  }
  .dividerContent {
    position: relative;
    z-index: 1;
  }
}

.appendDivider {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -71px;
    left: 16%;
    transform: translateY(-50%);
    border-right: 2px solid;
    height: 100%;
    z-index: -1;
    overflow: hidden;
  }
  &.horizontal_append_divider {
    // width: 20%;
    .inner-shadow {
      transition: 0.2s all ease;
      box-shadow: inset 0px 0px 7px 0px #0000003b;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      // right: -7%;
      // right: -53%;
      right: -50%;
      // left: 0;

      left: unset;
      bottom: unset;
      transform: translate(0, -50%);
      // background-color: #000;
      width: 98%;
      z-index: -1;
      overflow: hidden;
      height: 4px;
    }
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
}

.parent_ho {
  transition: 0.2s all ease;
  .child_ho {
    transition: 0.2s all ease;
  }
  &:hover {
    background-color: rgb(211, 229, 249) !important;
    .child_ho {
      background-color: rgb(211, 229, 249) !important;
      &.inner-shadow {
        box-shadow: inset 0px 0px 7px 0px rgb(255, 255, 255);
      }
    }
  }
}
.last_child {
  &:last-child {
    .horizontal_append_divider {
      &::after {
        display: none;
      }
    }
  }
  // &:nth-last-child(2) {
  //   .horizontal_append_divider {
  //     &::after {
  //       right: 0;
  //     }
  //   }
  // }
}
/* Border End*/

.dataTables {
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      &::after {
        inset-inline-start: 22%;
      }
    }
  }
  .ant-table-thead {
    .ant-table-cell {
      &:first-child {
        border-top-left-radius: 20px !important;
        border-bottom-left-radius: 20px !important;
      }
      &:last-child {
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
      }
    }
  }
  // .ant-table-measure-row {
  //   height: 8px !important;
  // }
  .dataTablesRow {
    .ant-table-cell,
    .ant-table-cell-row-hover {
      &:hover {
        .tableRowHover,
        .link {
          color: rgb(2 132 199);
        }
      }
      .ant-table-cell-row-hover {
        .link {
          color: rgb(2 132 199);
        }
      }
      &:first-child {
        border-top-left-radius: 20px !important;
        border-bottom-left-radius: 20px !important;
      }
      &:last-child {
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
      }
      &.ant-table-cell-row-hover {
        &:first-child {
          border-top-left-radius: 20px !important;
          border-bottom-left-radius: 20px !important;
        }
        &:last-child {
          border-top-right-radius: 20px !important;
          border-bottom-right-radius: 20px !important;
        }
      }
    }
  }
}

.formCheck {
  .form-check-input {
    font-size: 1rem;
    margin: 0;
    border-radius: 3px;
    flex-grow: 1;
    &:checked {
      background-color: $primary_color;
      border-color: $primary_color;
    }
  }
}

.verifyOtpBox {
  .ReactInputVerificationCode__item {
    border-radius: 10px;
    font-weight: 500;
    width: 3.5rem;
    height: 3.5rem;
    line-height: 3.5rem;

    @include responsivemax(520) {
      width: 2.5rem;
      height: 2.5rem;
      line-height: 2.5rem;
      font-size: 1rem;
    }
    @include responsivemax(390) {
      width: 2rem;
      height: 2rem;
      line-height: 2.1rem;
      font-size: 0.856rem;
    }
  }
  &.verifyOtpBoxDark {
    .ReactInputVerificationCode__item {
      color: #000;
      &.is-active {
        box-shadow: inset 0 0 0px 0px #8d8d8d !important;
        border: 1px solid $purpleDark !important;
      }
    }
  }
}

.dark .verifyOtpBox {
  .ReactInputVerificationCode__item {
    border-radius: 10px;
    font-weight: 500;
    width: 3.5rem;
    height: 3.5rem;
    line-height: 3.5rem;

    @include responsivemax(520) {
      width: 2.5rem;
      height: 2.5rem;
      line-height: 2.5rem;
      font-size: 1rem;
    }

    @include responsivemax(390) {
      width: 2rem;
      height: 2rem;
      line-height: 2.1rem;
      font-size: 0.856rem;
    }
  }

  &.verifyOtpBoxDark {
    .ReactInputVerificationCode__item {
      color: $white;

      &.is-active {
        box-shadow: inset 0 0 0px 0px #8d8d8d !important;
        border: 1px solid $purpleDark !important;
      }
    }
  }
}
.ant-notification {
  .ant-notification-notice-message {
    margin: 0;
  }
}
.scrollCss,
div,
.card_table .ant-table-body,
.ant-drawer-body,
.ant-table-content,
.ant-table .ant-table-body {
  touch-action: manipulation;
  // overflow: auto!important;
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    cursor: pointer !important;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(2, 132, 199, 0.3) !important;
    border-radius: 10px !important;
    // margin-top: 5px;
    // margin-bottom: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary_color !important;
    // outline: 1px solid rgba(112, 128, 144, 0.212);
    border-radius: 10px !important;
  }
  &.scrollHide {
    &::-webkit-scrollbar {
      opacity: 0 !important;
      border: none;
      background-color: transparent !important;
    }
    &::-webkit-scrollbar {
      width: 0px !important;
      height: 0px !important;
      cursor: pointer !important;
    }

    &::-webkit-scrollbar-track {
      opacity: 0 !important;
      border: none;
      box-shadow: none !important;
      background-color: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
      opacity: 0 !important;
      border: none;
      background-color: transparent !important;
    }
  }
}
.dark-drawer {
  &.scrollCss,
  .ant-table-body,
  .ant-drawer-body,
  .ant-table-content {
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgb(2, 132, 199, 0.3) !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $purpleDark !important;
    }
  }
}

.scrollHide {
  &::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
    cursor: pointer !important;
    display: none;
    // margin: -10px;
  }
}
.ant-select {
  // min-height: 35px;
  // min-height: 43px;
  min-height: 39px;
  &.ant-select-single {
    // min-height: 35px;
    // min-height: 43px;
    min-height: 39px;
  }
  // &.ant-select-outlined,
  &.ant-color-picker-format-select {
    // min-height: unset;
  }
}
.grecaptcha-badge {
  visibility: hidden !important;
}
.billing_table {
  .ant-table-cell {
    padding: 10px 5px !important;
    border-color: #ebebeb !important;
    background-color: transparent !important;
    border-style: dashed !important;
    border-radius: 0 !important;
  }
  .ant-table-body {
    // overflow-y: auto !important;
    overflow-y: hidden !important;
  }
  .ant-table-thead {
    .ant-table-column-sorters {
      .ant-table-column-title {
        max-width: 138px;
      }
      justify-content: flex-start;
    }
    .ant-table-column-has-sorters {
      // .ant-table-column-sorters {
      //   .ant-table-column-title {
      //     flex: none;
      //   }
      // }
      &:nth-child(3) {
        .ant-table-column-sorters {
          justify-content: flex-end !important;
          width: 88% !important;
          .ant-table-column-title {
            flex: none !important;
          }
        }
      }
    }
  }

  .dataTables {
    .ant-table-measure-row {
      height: 0px !important;
    }
    .dataTablesRow,
    .ant-table-thead {
      .ant-table-cell {
        border-radius: 0 !important;
        &:hover,
        &.ant-table-cell-row-hover {
          border-radius: 0 !important;
        }
      }
    }
  }

  .ant-pagination {
    line-height: 0;
    .ant-pagination-next,
    .ant-pagination-prev {
      line-height: 0;
    }
    .ant-select {
      &.ant-select-show-search {
        .ant-select-selector,
        .ant-select-selection-item {
          cursor: pointer !important;
        }
      }
    }

    .ant-select-selection-search,
    .anticon-search.ant-select-suffix {
      display: none;
    }
    .ant-pagination-jump-next {
      line-height: 2;
    }
  }
}
.ant-table-cell {
  &:first-child {
    .ant-table-column-sorters {
      .ant-table-column-title {
        max-width: 150px;
      }
    }
  }
}

.ant-pagination {
  line-height: 0;
  .ant-pagination-next,
  .ant-pagination-prev {
    line-height: 0;
  }
  .ant-select {
    &.ant-select-show-search {
      .ant-select-selector,
      .ant-select-selection-item {
        cursor: pointer !important;
      }
    }
  }

  .ant-select-selection-search,
  .anticon-search.ant-select-suffix {
    display: none;
  }
  .ant-pagination-jump-next {
    line-height: 2;
  }
}
// Hover Effect

.parentHover {
  .childHover {
    opacity: 0;
    transition: 0.5s all ease;
  }
  &:hover {
    .childHover {
      opacity: 1;
    }
  }
}

// .ant-tooltip-arrow {
//   display: none !important;
// }

// ordered ticket card after border

.borderAfter {
  // border-right: 1px dashed #cccccc;
  border-style: dashed;
  &::before {
    content: '';
    position: absolute;
    top: -1rem;
    right: -1rem;
    background-color: $white;
    width: 2rem;
    height: 2rem;
    z-index: 1;
    border-radius: 99px;
    box-shadow: inset rgba(100, 100, 100, 0.16) 0px -5px 5px -3px;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: -1rem;
    right: -1rem;
    background-color: $white;
    width: 2rem;
    height: 2rem;
    z-index: 1;
    border-radius: 99px;
    box-shadow: inset rgb(100 100 100 / 16%) 0px 5px 5px -3px;
  }
  &.borderAfterDark {
    &::before {
      background-color: #262626;
    }
    &::after {
      background-color: #262626;
    }
  }
}

// Checkout ticket card after border

.borderAfterVertical {
  // border-right: 1px dashed #cccccc;
  position: relative;
  border-style: dashed;
  &::before {
    content: '';
    position: absolute;
    bottom: -1rem;
    left: -1rem;
    background-color: #f9fafb;
    width: 2rem;
    height: 2rem;
    z-index: 1;
    border-radius: 99px;
    box-shadow: inset rgba(100, 100, 100, 0.16) -5px 0px 5px -3px;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: -1rem;
    right: -1rem;
    background-color: #f9fafb;
    width: 2rem;
    height: 2rem;
    z-index: 1;
    border-radius: 99px;
    box-shadow: inset rgba(100, 100, 100, 0.16) 5px 0px 5px -3px;
  }

  &.borderAfterDark {
    &::before {
      background-color: #000;
    }
    &::after {
      background-color: #000;
    }
  }
}

.screenHeight {
  // min-height: calc(100vh - 310px);
  // max-height: calc(100vh - 310px);
  // min-height: calc(100vh - 291px);
  // max-height: calc(100vh - 291px);
  // min-height: calc(100vh - 300px);
  // max-height: 66vh;
  max-height: 500px;
  @include responsivemin(1200) {
    max-height: calc(100vh - 400px);
  }
}
.book_screen {
  // max-height: 500px;
  @include responsivemin(1200) {
    max-height: 85vh;
  }
}

// MUI Tabs

.mui-CustomTabs {
  &.MuiTabs-root {
    .MuiButtonBase-root {
      align-items: start;
      text-transform: capitalize;
      font-family: 'Inter', Helvetica, Arial, sans-serif;
      border-radius: 0.75rem;
      color: $black;
      &.Mui-selected {
        color: $primary_color;
        background-color: rgb(241 245 249);
      }
      &:hover {
        color: $primary_color;
      }
    }
    .MuiTabs-indicator {
      display: none;
    }
  }
}
.MuiTabs-root,
.MuiButtonBase-root {
  font-size: 14px !important;
}
.MuiTabs-root {
  .MuiButtonBase-root {
    // font-family: 'Poppins', sans-serif !important;
    font-family: 'Inter', Helvetica, Arial, sans-serif !important;
    color: $n_background_color !important;
    font-weight: 500 !important;
    &.Mui-selected {
      background-color: $white;
      color: $primary_color_hover !important;
      font-weight: 500 !important;
    }
  }
}
.horizontalTab {
  &.MuiTabs-root {
    .MuiButtonBase-root {
      &.Mui-selected {
        background-color: $white;
        color: $primary_color !important;
        font-weight: 500 !important;
      }
    }
  }
  .MuiTabs-indicator {
    height: 3px;
  }
}
// Custom Popover

.ant-popover-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  border-radius: 0 0 2px;
  pointer-events: none;
  width: 16px;
  height: 16px;
  overflow: hidden;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%) translateY(100%) rotate(180deg);

  &::before {
    position: absolute;
    bottom: 0;
    inset-inline-start: 0;
    width: 16px;
    height: 8px;
    background: var(--antd-arrow-background-color);
    clip-path: path(
      'M 0 8 A 4 4 0 0 0 2.82842712474619 6.82842712474619 L 6.585786437626905 3.0710678118654755 A 2 2 0 0 1 9.414213562373096 3.0710678118654755 L 13.17157287525381 6.82842712474619 A 4 4 0 0 0 16 8 Z'
    );
    content: '';
    background-color: $white;
  }
  &::after {
    content: '';
    position: absolute;
    width: 8.970562748477143px;
    height: 8.970562748477143px;
    bottom: 0;
    inset-inline: 0;
    margin: auto;
    border-radius: 0 0 2px 0;
    transform: translateY(50%) rotate(-135deg);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
    z-index: 0;
    background: transparent;
  }
}

.ant-steps-icon {
  // top: -;
  .anticon-check {
    margin-top: 9px !important;
    display: flex !important;
    justify-content: center !important;
  }
}

.check_margin {
  // min-height: auto !important;
  margin-bottom: 0 !important;
  margin-right: 0px !important;
  // .form-check-input {
  //   margin-bottom: 0;
  //   margin-top: 0;
  // }
}

.darkPopover {
  .ant-popover-inner {
    background-color: transparent;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    padding: 0;
    box-shadow: unset;
  }
}
// dashboard Css

.informationBelow {
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    list-style-type: none;
    border: 1px solid var(--color-border-2);
    border-radius: 10px;
    li {
      flex: 1 0;
      text-align: center;
      padding: 15px 10px;
      border-right: 1px solid var(--color-border-2);
      font-size: 14px;

      &:last-child {
        border-right: none;
      }
    }
  }
}
// last hide
.lastHide {
  &:last-child {
    .lastItem {
      display: none;
    }
  }
}
// radio check

.ant-checkbox-inner {
  border-color: #787878;
}
.ant-checkbox {
  &.ant-checkbox-checked {
    &.ant-checkbox-disabled {
      .ant-checkbox-inner {
        background: rgba(0, 0, 0, 0.04) !important;
      }
    }
  }
}

// .form-check-input {
//   // border-color: #787878;
//   // background-image: none;
//   &:checked[type='radio'] {
//     border-color: $primary_color;
//     // background-image: none;
//   }
// }
.tableCheck {
  .form-check-input {
    border-color: #787878;
    // background-image: none;
    &:checked[type='radio'] {
      border-color: $primary_color;
      background-image: none;
    }
  }
}
.checkBoxError {
  .ant-checkbox-inner {
    border-color: #ff6767;
    box-shadow: 0px 0px 4px 1px #ff9898;
  }
  &:hover {
    .ant-checkbox-inner {
      border-color: #ff6767 !important;
      box-shadow: 0px 0px 4px 1px #ff9898;
    }
  }
}

// Floating Form

.dark-floating-label {
  label {
    color: #fff !important;
    &::after {
      background-color: transparent !important;
    }
  }
}

// tour tab css
.tourActive {
  // .tableCheck .form-check-input:checked[type='radio'] {
  //   border-color: $tourActive !important;
  // }
  // .form-check-input:checked {
  //   background-color: $tourActive !important;
  //   border-color: $tourActive !important;
  // }
  // .ant-checkbox {
  //   &.ant-checkbox-checked {
  //     .ant-checkbox-inner {
  //       background-color: $tourActive !important;
  //       border-color: $tourActive !important;
  //     }
  //   }
  //   &.ant-checkbox-indeterminate {
  //     .ant-checkbox-inner {
  //       &::after {
  //         background-color: $tourActive !important;
  //       }
  //     }
  //   }
  // }
  // .ant-switch {
  //   &.ant-switch-checked {
  //     .ant-switch-inner {
  //       background-color: $tourActive !important;
  //     }
  //   }
  // }
  // .focus\:ring-sky-500:focus {
  //   --tw-ring-color: rgb(8, 145, 178 / var(--tw-ring-opacity)) !important;
  // }

  .scrollCss {
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgb(147, 51, 234, 0.3) !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $tourActive !important;
    }
  }
  // .bgPrimary,
  // .btnPrimary {
  //   background-color: $tourActive !important;
  // }
  // .horizontal_append_divider {
  //   &::after {
  //     background-color: rgb(14 116 144 / var(--tw-bg-opacity));
  //   }
  // }
}
.dark .checkboxPurple {
  .ant-checkbox-wrapper {
    .ant-checkbox {
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: $purpleDark;
          border-color: $purpleDark;
        }
      }
    }
    &:hover {
      .ant-checkbox {
        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: $purpleDark !important;
            border-color: $purpleDark !important;
          }
        }
      }
    }
  }
}
.checkboxPurple {
  .ant-checkbox-wrapper {
    .ant-checkbox {
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: #0ea5e9;
          border-color: #0284c7;
        }
      }
    }

    &:hover {
      .ant-checkbox {
        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: #0284c7 !important;
            border-color: #0284c7 !important;
          }
        }
      }
    }
  }
}
.scrollBarThumb {
  &::-webkit-scrollbar {
    width: 2px !important;
    height: 2px !important;
    cursor: pointer !important;
  }
}
.scrollBarPurpleColor {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(147, 51, 234, 0.3) !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $tourActive !important;
  }
}
.eventActive {
  // .tableCheck .form-check-input:checked[type='radio'] {
  //   border-color: $eventActive !important;
  // }
  // .form-check-input:checked {
  //   background-color: $eventActive !important;
  //   border-color: $eventActive !important;
  // }
  // .ant-checkbox {
  //   &.ant-checkbox-checked {
  //     .ant-checkbox-inner {
  //       background-color: $eventActive !important;
  //       border-color: $eventActive !important;
  //     }
  //   }
  //   &.ant-checkbox-indeterminate {
  //     .ant-checkbox-inner {
  //       &::after {
  //         background-color: $eventActive !important;
  //       }
  //     }
  //   }
  // }
  // .ant-switch {
  //   &.ant-switch-checked {
  //     .ant-switch-inner {
  //       background-color: $eventActive !important;
  //     }
  //   }
  // }
  .focus\:ring-sky-500:focus {
    --tw-ring-color: rgb(8, 145, 178 / var(--tw-ring-opacity)) !important;
  }

  // .scrollCss {
  //   &::-webkit-scrollbar-track {
  //     box-shadow: inset 0 0 6px $eventActive !important;
  //   }

  //   &::-webkit-scrollbar-thumb {
  //     background-color: $eventActive !important;
  //   }
  // }
  // .bgPrimary,
  // .btnPrimary {
  //   background-color: $eventActive !important;
  // }
}
.tourSelect {
  .MuiButtonBase-root {
    &.Mui-selected {
      color: $tourActive !important;
    }
  }
  .MuiTabs-indicator {
    // background-color: rgb(21 94 117);
    background-color: $tourActive;
  }
}

//

// .font-medium {
//   font-weight: 400;
// }

// .font-semibold {
//   font-weight: 500;
// }

// last discloser find

.lastDiscloseFind {
  &:last-child {
    .lastBorder {
      border-bottom-width: 0px;
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}
.ant-table {
  .ant-table-tbody {
    .ant-table-cell {
      font-size: 13px;
    }
  }
}

// Card Table
table {
  border-spacing: 0 4px;
  .ant-table-thead {
    font-family: $font_family_inter;
    .ant-table-cell {
      font-family: $font_family_inter;
      font-weight: 400 !important;
    }
  }
}
.card_table {
  table {
    // border-spacing: 0 8px;
    // border-spacing: 0 3px;
    .ant-table-thead {
      .ant-table-cell {
        padding: 9px 16px !important;
        border-spacing: 0;
        &:first-child {
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
        }
        &:last-child {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
        }
      }
    }
    .ant-checkbox-inner {
      width: 16px;
      height: 16px;
    }

    .dataTablesRow {
      tr {
        &:first-child {
          td {
            border-top-width: 0px !important;
          }
        }
      }
      .ant-table-cell {
        border: 1px solid #f0f0f0;
        border-bottom-width: 0px !important;
        // padding: 8px 10px !important;
        border-spacing: 0;
        &:not(&:first-child, &:last-child) {
          border-left-width: 0px;
          border-right-width: 0px;
        }
        &:first-child {
          border-right-width: 0px;
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
          border-left-width: 0px;
        }
        &:last-child {
          border-left-width: 0px;
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          border-right-width: 0px;
        }
        &.ant-table-cell-row-hover {
          &:first-child {
            border-top-left-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
          }
          &:last-child {
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
          }
        }
      }
    }
  }
  .min-height-table {
    .ant-table {
      &.ant-table-fixed-header {
        .ant-table-body {
          overflow: auto !important;
          min-height: 129px;
          // padding-right: 5px;
          // padding-left: 5px;
          &::-webkit-scrollbar {
            width: 2px !important;
            height: 2px !important;
            cursor: pointer !important;
          }
        }
      }
    }
  }

  .table-spacing-none {
    .ant-table {
      table {
        // margin-top: -10px;
        margin-bottom: -10px;
        border-spacing: 0 4px;
      }
    }
  }

  .dataTables {
    .ant-pagination {
      border-top: 1px solid var(--color-border-1);
      margin-top: 10px !important;
      padding-top: 10px !important;
    }
    .ant-spin {
      max-height: unset;
    }
    .ant-table-placeholder {
      .ant-table-cell {
        .ant-table-expanded-row-fixed {
          min-height: 400px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.accordionHeight {
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  overflow: hidden;
  // "height: 0" not work with css transitions
  max-height: 0;

  &.accordion-content {
    // If use the "height: auto", the effect not works. Is necessary some value for the CSS create a CSS animate, and we can use "max-height" with a great value for emulate this effect.
    max-height: 300px;
  }

  &.fullMaxHeight {
    max-height: 100%;
  }
  // onlySmallScreen {
  //   @include responsivemin(768) {
  //   }
  // }
}

.accordionWidth {
  -webkit-transition: max-width 0.5s, opacity 2s;
  -moz-transition: max-width 0.5s, opacity 2s;
  -ms-transition: max-width 0.5s, opacity 2s;
  -o-transition: max-width 0.5s, opacity 2s;
  transition: max-width 0.5s, opacity 2s;
  overflow: hidden;
  @include responsivemin(1200) {
    opacity: 0;
    // max-width: 0;
  }

  &.accordion-content {
    // If use the "height: auto", the effect not works. Is necessary some value for the CSS create a CSS animate, and we can use "max-height" with a great value for emulate this effect.

    @include responsivemin(1200) {
      // max-width: 320px;
      opacity: 1;
    }
  }
}

/*   Dropdownn Overlay   */

.dark {
  .dropdownOverlay {
    .ant-dropdown-menu-item-active {
      background-color: #eaf4fb !important;
    }

    .ant-dropdown-menu-item-active {
      &:hover {
        background-color: #e9f5ff !important;
      }
    }
    &.dowpdownDark {
      border: 1px solid var(--color-border-2) !important;
      border-radius: 10px !important;
      .ant-dropdown-menu {
        background-color: rgb(var(--color-base-1));
        box-shadow: var(--color-shadow-soft) !important;
      }
      .ant-dropdown-menu-item-selected {
        background-color: rgb(var(--color-base-2)) !important;
      }
      .ant-dropdown-menu-item-active:hover {
        background-color: rgb(var(--color-base-2)) !important;
      }
    }
  }
}

.footerNoBorder {
  .ant-drawer-footer {
    border-top-width: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
:where(
    .css-dev-only-do-not-override-1ae8k9u
  ).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
  background: unset !important;
}
/* ============ Remove Video Control =============== */
.__removeVideoControl {
  video::-webkit-media-controls {
    display: none;
  }

  /* Could Use thise as well for Individual Controls */
  video::-webkit-media-controls-play-button {
  }

  video::-webkit-media-controls-volume-slider {
  }

  video::-webkit-media-controls-mute-button {
  }

  video::-webkit-media-controls-timeline {
  }

  video::-webkit-media-controls-current-time-display {
  }
}
/* ============ Remove Video Control =============== */

.filterDrop {
  .ant-select-selection-item {
    .selectedValue {
      display: none;
    }
  }
}
