.ant-upload {
  width: 100% !important;
}

.ant-picket .ant-picker-input > input {
  color: white !important;
}

.ant-picker-input {
  color: white !important;
}