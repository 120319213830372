.btnPrimary {
  background-color: $primary_color;
  color: $white;
  border: 1px solid $primary_color;
  height: 50px;
  // font-size: 1rem;
  font-size: 14px;
  font-weight: 500;
  padding: 0.375rem 18px;
  min-width: 2rem;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 100%;
  // svg {
  //   margin-right: 5px;
  // }
  &.max-content {
    min-width: auto;
    width: max-content;
  }
  &.min-auto {
    min-width: auto;
  }
  &.height-auto {
    height: auto;
  }
  @include responsivemin(540) {
    min-width: 100px;
    &.min-100 {
      min-width: 100px;
    }
  }
  &:focus,
  &:active,
  &:hover {
    background-color: $primary_color_hover !important;
    color: $white !important;
    box-shadow: none !important;
    outline: none !important;
  }
  &:disabled {
    border-color: transparent;
    background-color: $primary_color;
    color: $white;
  }
  &.fs-1em {
    font-size: 1em;
  }
  &[class*='hpx-35'] {
    height: auto !important;
  }
  &[class*='hpx-3'] {
    height: auto !important;
  }
}
.btnPrimary2 {
  background-color: $primary_color2;
  color: $dark_blue;
  border: 1px solid $primary_color2;
  // font-size: 1rem;
  font-size: 14px;
  transition: all 0.3s ease;
  // width: 100%;
  svg {
    margin-right: 5px;
  }
  &:focus,
  &:active,
  &:hover {
    background-color: $primary_color2 !important;
    color: $dark_blue !important;
    box-shadow: none !important;
    outline: none !important;
  }

  &.fs-1em {
    font-size: 1em;
  }
}
// button secondary

.btnSecondary {
  background-color: transparent;
  color: $black;
  border: 1px solid $secondary_color;
  // border-radius: 140px;
  height: 50px;
  // font-size: 1rem;
  font-size: 14px;
  padding: 0.375rem 18px;
  min-width: 2rem;
  transition: all 0.3s ease;

  &.btnDark {
    color: $white;

    &:hover,
    &:focus {
      color: $black;
    }
  }
  // width: 100%;
  // svg {
  //   margin-right: 5px;
  // }
  &.max-content {
    min-width: auto;
    width: max-content;
  }
  &.min-auto {
    min-width: auto;
  }
  &.height-auto {
    height: auto;
  }
  @include responsivemin(540) {
    min-width: 100px;
    &.min-100 {
      min-width: 100px;
    }
  }
  &.Icon {
    transition: transform 0.3s ease;
  }

  &:focus,
  &:active,
  &:hover {
    background-color: $secondary_color_hover !important;
    color: $black !important;
    box-shadow: none !important;
    outline: none !important;
    border-color: transparent !important;

    &.Icon {
      transform: scale(1.1);
    }
  }

  &:disabled {
    border-color: $secondary_color;
    // cursor: not-allowed;
    pointer-events: all;
  }
}

.btn-headerLanding {
  background-color: $white;
  font-size: 14px;
  // padding: 8px 18px;
  min-width: 2rem;
  transition: all 0.3s ease;

  &:focus,
  &:active,
  &:hover {
    background-color: $secondary_color_hover !important;
    box-shadow: none !important;
    outline: none !important;
    border-color: transparent !important;

    &.Icon {
      transform: scale(1.1);
    }
  }

  &.fs-1em {
    font-size: 1em;
  }
}

.btnBookNow {
  background-color: $megenta;
  color: $white;
  border: 1px solid $megenta;
  // font-size: 1rem;
  font-size: 14px;
  transition: all 0.3s ease;
  // width: 100%;
  svg {
    margin-right: 5px;
  }
  &:focus,
  &:active,
  &:hover {
    background-color: rgb($megenta, 0.9) !important;
    color: $white;
    box-shadow: none;
    outline: none;
  }

  &.fs-1em {
    font-size: 1em;
  }
}
