.upcomingDataTables {
  .ant-table {
    background-color: transparent;
    table {
      border-spacing: 0 10px;
      .ant-table-cell-scrollbar {
        display: none;
      }
    }
    .ant-table-body {
      touch-action: manipulation;
      &::-webkit-scrollbar {
        width: 4px !important;
        height: 4px !important;
        cursor: pointer !important;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgb(2, 132, 199, 0.3) !important;
        border-radius: 20px;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $primary_color;
        margin-top: 10px;
        // outline: 1px solid rgba(112, 128, 144, 0.212);
        border-radius: 20px;
      }
    }
    .ant-table-thead {
      .ant-table-cell {
        background-color: transparent;
        color: $white;
        border-bottom: 0px;

        &::before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      .upcomingDataTablesRow {
        .ant-table-cell {
          background: $table_row_color;
          border-width: 0px !important;

          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
          &.ant-table-cell-row-hover {
            // background-color: transparent !important;
            background: rgb(47 81 141 / 22%) !important;
          }
        }
      }
    }
  }
}
