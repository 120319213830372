.backdrop-blur-none {
  -webkit-backdrop-filter: blur(0);
}
.backdrop-blur-sm {
  -webkit-backdrop-filter: blur(4px);
}
.backdrop-blur {
  -webkit-backdrop-filter: blur(8px);
}
.backdrop-blur-md {
  -webkit-backdrop-filter: blur(12px);
}
.backdrop-blur-lg {
  -webkit-backdrop-filter: blur(16px);
}
.backdrop-blur-xl {
  -webkit-backdrop-filter: blur(24px);
}
.backdrop-blur-2xl {
  -webkit-backdrop-filter: blur(40px);
}
.backdrop-blur-3xl {
  -webkit-backdrop-filter: blur(64px);
}

.ant-btn-primary {
  background-color: #1677ff;
}
