.parentCardHover {
  .childCardHover {
    transition: 0.5s all ease;
    cursor: pointer;
    &:hover {
      transform: scale(1.02);
    }
  }
  &:hover {
    .childCardHover {
      transform: scale(1.02);
    }
  }
}
