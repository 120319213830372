.errorPage {
  min-height: calc(100vh - 280px);
  display: flex;
  align-items: center;
  h1 {
    font-size: 7.5em;
    margin: 15px 0px;
    font-weight: bold;
  }
  h2 {
    font-weight: bold;
  }
  .errorbtn {
    z-index: 1;
    overflow: hidden;
    background: transparent;
    position: relative;
    padding: 8px 50px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1em;
    letter-spacing: 2px;
    transition: 0.2s ease;
    font-weight: bold;
    margin: 20px 0px;
    &.green {
      border: 4px solid $primary_color;
      color: $primary_color;
      border-radius: 30px;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 0%;
        height: 100%;
        background: $primary_color;
        z-index: -1;
        transition: 0.2s ease;
        border-radius: 30px;
      }
      &:hover {
        color: $white;
        background: $primary_color;
        transition: 0.2s ease;
        border-radius: 30px;
        &:before {
          width: 100%;
          border-radius: 30px;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    &.container {
      margin-top: 70px;
      margin-bottom: 70px;
    }
  }
}
