.cstm-drwr {
  font-family: 'Poppins', sans-serif;
}

.cstm-drwr .ant-col label {
  color: #fff;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 300 !important;
}

.cstm-drwr .ant-form-item-control-input-content input {
  height: 45px;
  font-family: 'Poppins', sans-serif;
  background-color: #221914;
  border: none;
  color: #fff;
  font-size: 15px;
}

.cstm-drwr .ant-form-item-control-input-content input::placeholder {
  color: #414141;
}

.cstm-drwr .ant-drawer-header {
  /* border-bottom: solid 1px #7B7B7B; */
  border-bottom: solid 1px #333;
}

.cstm-drwr .anticon-close {
  color: #fff !important;
}

.or-sep-line {
  font-family: 'Poppins', sans-serif;
  position: relative;
  z-index: 0;
}

.or-sep-line::after {
  content: '';
  position: absolute;
  top: 16px;
  left: -22px;
  background-color: #333;
  height: 1px;
  width: 110%;
}

.or-sep-line span {
  background-color: #000;
  z-index: 1;
  position: relative;
  padding: 10px;
  color: #535353;
  font-size: 20px;
}

.scn-sect span {
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.scn-sect svg {
  margin: 25px auto;
}

.btn-cancel {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  padding: 7px 50px;
  margin-right: 10px;
  height: auto;
  border: none;
}

.btn-cancel:hover {
  background-color: #333;
  color: #fff !important;
}

.btn-join {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  padding: 7px 50px;
  height: auto;
  background-color: #7c3aed;
  border: none;
}

.btn-join:hover {
  background-color: #6d2edb !important;
  color: #fff !important;
}
.selectTickets .ant-space-item {
  background-color: #221914;
  padding: 20px;
  color: #fff;
  border-radius: 5px;
}
.selectTickets .ant-space-item label {
  color: #fff;
  padding-left: 15px;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
}
.selectTickets .ant-radio-group {
  width: 100%;
  display: flex;
  justify-content: center;
}
.selectTickets .ant-radio-group .ant-space {
  width: 85%;
}
.selectTickets
  .ant-space-item
  .ant-radio-wrapper-checked
  .ant-radio-inner::after {
  opacity: 0;
}
.selectTickets .ant-space-item .ant-radio-checked .ant-radio-inner {
  background-color: #7c3aed !important;
  border-color: #a06bfb;
}
.select-ticket-btns {
  position: absolute;
  bottom: 25px !important;
  right: 25px !important;
}
.profile-top-sect {
  position: absolute;
  top: 10px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.usr-dp {
  background-color: #999;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  margin-left: 10px;
}
.btm-strip {
  background-color: rgba(84, 73, 73, 0.7) !important;
  border-radius: 18px 18px 0 0;
}
.btn-strp {
  background-color: rgba(255, 255, 255, 0.09) !important;
  padding: 8px;
  border-radius: 10px;
}
.btn-exit-event {
  background-color: #b81212;
  color: #fff;
  padding: 8px 15px;
  border-radius: 10px;
  // margin-right: 35px;
  position: relative;
}
// .btn-exit-event::after {
//   content: '';
//   background-color: #fff;
//   height: 37px;
//   width: 1px;
//   position: absolute;
//   right: -18px;
//   top: 2px;
// }
.btn-rejoin {
  background-color: #923cff;
  color: #fff;
  padding: 10px 30px;
  border-radius: 6px;
  font-size: 18px;
}
.btn-homepage {
  background-color: #413b3b;
  color: #fff;
  padding: 10px 30px;
  border-radius: 10px;
  font-size: 18px;
  border-radius: 6px;
}
.btn-cstm {
  background-color: rgba(255, 255, 255, 0.2);
  height: 100px;
  width: 100px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cstm-drwr .ant-drawer-header {
  border-radius: 20px 0 0 0;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  // .ant-drawer-content-wrapper {
  //   height: 75vh !important;
  // }
  .cstm-drwr .ant-col label {
    font-size: 15px;
  }
  .scn-sect span {
    font-size: 15px;
  }
  .btn-cancel {
    font-size: 16px;
    padding: 7px 35px;
  }
  .btn-join {
    font-size: 16px;
    padding: 7px 35px;
  }
  .cstm-drwr .ant-drawer-header {
    border-radius: 25px 25px 0px 0px;
  }
  .selectTickets .ant-space-item label {
    font-size: 14px;
  }
  .selectTickets .ant-space-item {
    padding: 15px;
  }
  .btn-rejoin {
    font-size: 16px;
    padding: 7px 35px;
  }
  .btn-homepage {
    font-size: 16px;
    padding: 7px 35px;
  }
  .scn-sect svg {
    height: 40px;
  }
  .cstm-icon svg {
    height: 18px;
  }
  // .btn-cstm {
  //   height: 70px;
  //   width: 70px;
  // }
  // .btn-cstm svg {
  //   height: 35px;
  // }
  .btn-strp-mob {
    background-color: rgba(255, 255, 255, 0.2);
    height: 50px;
    width: 50px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-strp-mob svg {
    height: 20px;
  }
}

.bodyPadding {
  .ant-drawer-content-wrapper {
    height: auto !important;
  }
  .ant-drawer-body {
    padding: 0 !important;
  }
}
