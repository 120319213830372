.footer_new {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  // background-color: rgb(25, 25, 25);
}

.footer {
  // margin-bottom: 40px;
  // border-top: 1px solid $n_secondary_border;
  h4 {
    font-family: $font_barlow;
  }

  .socialLinks {
    padding: 0;
    display: flex;
    margin-top: 25px;
    list-style-type: none;
    margin-bottom: 20px;
    @include responsivemax(576) {
      flex-wrap: wrap;
    }
    li {
      margin-right: 10px;
      margin-top: 8px;
      border-right: 1px solid $primary_color;
      padding-right: 10px;
      &:last-child {
        border-color: transparent;
      }
      @include responsivemax(576) {
        flex: 0 0 auto;
        width: 33.33%;
        margin-right: unset;
        border: unset;
        a.mobileBrdr {
          border-right: 1px solid $primary_color;
          width: 84%;
          display: inline-block;
        }
      }
      a {
        font-family: $font_barlow;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 26px;
        // color: $white;
        text-decoration: none;
        @include responsivemax(991) {
          font-size: 18px;
          line-height: 22px;
        }

        @include responsivemax(991) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  .otherLinks {
    padding: 0;
    margin: 0;
    display: flex;
    width: 70%;
    list-style-type: none;
    @include responsivemax(576) {
      width: 100%;
    }

    li {
      // width: -webkit-fill-available;
      width: 33.34%;
      a {
        font-family: $font_barlow;
        font-style: normal;
        // font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        // color: #ffffff;
        text-decoration: none;
        @include responsivemax(991) {
          font-size: 12px;
          line-height: 16px;
        }

        @include responsivemax(991) {
          font-size: 10px;
          line-height: 14px;
        }
      }
    }

    // li:first-child {
    //   a {
    //     font-weight: 900;
    //     color: $purple;
    //   }
    // }
  }

  .text-right {
    text-align: right;
    margin-top: 40px;
  }

  .footerLogo {
    max-width: 250px;
    width: 100%;
  }
}
.theme-light {
  .footer {
    // background-color: $white;
    // border-color: $n_secondary_border;
    .heading {
      color: $primary_color;
    }
    .socialLinks,
    .otherLinks {
      li {
        .mobileBrdr,
        a {
          color: $black;
        }
      }
    }
  }
}
