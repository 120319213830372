$background-color: Thistle;
$width: 500;
$circle-size: 50;

.ticket {
  //   width: $width + px;
  width: 100%;
  height: auto;
  //   background-color: white;
  //   margin: 25px auto;
  position: relative;
}

// .holes-top {
//   height: $circle-size + px;
//   width: $circle-size + px;
//   background-color: #e0e0e0;
//   border-radius: 50%;
//   position: absolute;
//   left: 50%;
//   margin-left: ($circle-size / -2) + px;
//   top: ($circle-size / -2) + px;

//   &:before,
//   &:after {
//     content: '';
//     height: $circle-size + px;
//     width: $circle-size + px;
//     background-color: #e0e0e0;
//     position: absolute;
//     border-radius: 50%;
//   }
//   &:before {
//     left: ($width / -2) + px;
//   }
//   &:after {
//     left: ($width / 2) + px;
//   }
// }

.holes-lower {
  position: relative;
  //   margin: 25px;
  border: 1px dashed #686868;
  //   &:before,
  //   &:after {
  //     content: '';
  //     height: $circle-size + px;
  //     width: $circle-size + px;
  //     background-color: #e0e0e0;
  //     position: absolute;
  //     border-radius: 50%;
  //   }

  //   &:before {
  //     top: -25px;
  //     left: ($circle-size / -1) + px;
  //   }
  //   &:after {
  //     top: -25px;
  //     left: ($width - $circle-size) + px;
  //   }
}

// .title {
//   padding: 50px 25px 10px;
// }

// .cinema {
//   color: #aaa;
//   font-size: 22px;
// }

// .movie-title {
//   font-size: 25px;
// }
.info {
  padding: 15px 25px;
}
.table_width {
  table {
    // width: 100%;
    //   font-size: 18px;
    //   margin-bottom: 15px;
    // tr {
    //   margin-bottom: 10px;
    // }

    th {
      text-align: left;
      &:nth-of-type(1) {
        width: 38%;
      }
      &:nth-of-type(2) {
        width: 40%;
      }
      &:nth-of-type(3) {
        width: 15%;
      }
    }

    // td {
    //   width: 33%;
    //   &.ant-table-selection-column {
    //     &:first-child {
    //       width: 2%;
    //     }
    //   }
    //   // font-size: 28px;
    // }
    .artistRow {
      td {
        width: inherit !important;
        &:first-child {
          width: 15% !important;
        }
      }
    }
  }
}
// .bigger {
//   font-size: 35px;
// }
.serial {
  padding: 25px;
  table {
    border-collapse: collapse;
    margin: 0 auto;
  }
  td {
    width: 3px;
    height: 50px;
  }
}
.numbers {
  td {
    // font-size: 16px;
    text-align: center;
  }
}

/*==================== Ticket Card Preview ========================*/

.ticket_card_preview {
}
.darken {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, #171414d6, rgba(48, 60, 72, 0));
}
