.select_screen_card {
  &:last-child {
    .last_class {
      display: none;
    }
  }
}

.scrollCss {
  &.scroll_lounge {
    &::-webkit-scrollbar {
      width: 2px !important;
      height: 2px !important;
      cursor: pointer !important;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgb(2, 132, 199, 0.3) !important;
      border-radius: 10px;
      // margin-top: 5px;
      // margin-bottom: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $purpleDark;
      // outline: 1px solid rgba(112, 128, 144, 0.212);
      border-radius: 10px;
    }
  }
}
