.banner_height {
  height: 30rem;
  max-height: 1426px;
  @include responsivemin(1200) {
    height: 33rem;
  }
  @include responsivemin(1400) {
    height: 35rem;
  }
  @include responsivemin(1800) {
    height: 38rem;
  }
  &.banner_height_auto {
    @include responsivemax(798) {
      min-height: 18rem;
      height: auto;
    }
  }

  &.banner_height_v2 {
    @include responsivemax(1200) {
      // padding-bottom: 100px;
      min-height: 35rem;
    }
    @include responsivemax(480) {
      // padding-bottom: 100px;
      min-height: 40rem;
    }
  }
}

.banner_swiper {
  .swiper-pagination {
    position: absolute;
    left: 50% !important;
    bottom: 20px !important;
    transform: translateX(-50%);
    width: auto !important;

    // @include responsivemin(540) {
    //   left: 4% !important;
    // }
    // @include responsivemin(1300) {
    //   left: 8% !important;
    // }
    // @include responsivemin(1400) {
    //   left: 12% !important;
    // }
    // @include responsivemin(1600) {
    //   left: 14% !important;
    // }
    @include responsivemin(768) {
      bottom: -5px !important;
    }
    .swiper-pagination-bullet {
      width: 5rem;
      height: 4px !important;

      @include responsivemax(540) {
        width: 3rem;
      }
      border-radius: 5px;
      background: linear-gradient(to right, rgb(2, 132, 199) 50%, rgb(208, 212, 224) 20%);
      background-size: 200% 100%;
      background-position: bottom right;
      opacity: 0.8;
      //   transition: all 0.3s ease;
      &.swiper-pagination-bullet-active {
        background-color: $white;
        background-position: bottom left;
        opacity: 1;
        transition: background-position 4s ease-out;
      }
    }
  }
  &.pagination-overlay {
    .swiper-pagination {
      @include responsivemin(768) {
        bottom: 33px !important;
      }
    }
  }

  &.thumbSwiper {
    .swiper-pagination {
      position: relative !important;
      bottom: unset !important;
      // top: 103px !important;
      bottom: 3px !important;
      .swiper-pagination-bullet {
        height: 5px !important;
        width: 3rem !important;
        @include responsivemin(540) {
          width: 3rem !important;
        }
        @include responsivemin(1400) {
          width: 4rem !important;
        }
      }
      @include responsivemin(1024) {
        // top: 118px !important;
        bottom: 3px !important;
      }
      @include responsivemin(1400) {
        // top: 140px !important;
        bottom: 3px !important;
      }
    }
    &.mobHidePagination {
      .swiper-pagination {
        display: none;
        @include responsivemin(1200) {
          display: block;
        }
      }
    }
  }
}
.card_swiper {
  .swiper-pagination {
    bottom: 4px !important;
    .swiper-pagination-bullet {
      width: 9px;
      height: 9px !important;
      border-radius: 5px;
      background: linear-gradient(to right, $white 50%, rgb(96, 102, 123) 50%);
      background-size: 200% 100%;
      background-position: bottom right;
      opacity: 0.8;
      //   transition: all 0.3s ease;
      &.swiper-pagination-bullet-active {
        background-color: $white;
        background-position: bottom left;
        opacity: 1;
        transition: background-position 4s ease-out;
      }
    }
  }
}
.event_detail_pagination {
  .swiper-pagination {
    bottom: 8px !important;
    .swiper-pagination-bullet {
      width: 9px;
      height: 9px !important;
      border-radius: 5px;
      background: linear-gradient(to right, $white 50%, rgb(96, 102, 123) 50%);
      background-size: 200% 100%;
      background-position: bottom right;
      opacity: 0.8;
      //   transition: all 0.3s ease;
      &.swiper-pagination-bullet-active {
        background-color: $white;
        background-position: bottom left;
        opacity: 1;
        transition: background-position 4s ease-out;
      }
    }
  }
}
.dark .banner_swiper {
  .swiper-pagination {
    position: absolute;
    left: 50% !important;
    bottom: 20px !important;
    transform: translateX(-50%);
    width: auto !important;

    // @include responsivemin(540) {
    //   left: 4% !important;
    // }
    // @include responsivemin(1300) {
    //   left: 8% !important;
    // }
    // @include responsivemin(1400) {
    //   left: 12% !important;
    // }
    // @include responsivemin(1600) {
    //   left: 14% !important;
    // }
    @include responsivemin(768) {
      bottom: -5px !important;
    }

    .swiper-pagination-bullet {
      width: 5rem;
      height: 4px !important;

      @include responsivemax(540) {
        width: 3rem;
      }

      border-radius: 5px;
      background: linear-gradient(to right, $white 50%, rgb(96, 102, 123) 50%);
      background-size: 200% 100%;
      background-position: bottom right;
      opacity: 0.8;

      //   transition: all 0.3s ease;
      &.swiper-pagination-bullet-active {
        background-color: $white;
        background-position: bottom left;
        opacity: 1;
        transition: background-position 4s ease-out;
      }
    }
  }

  &.pagination-overlay {
    .swiper-pagination {
      @include responsivemin(768) {
        bottom: 33px !important;
      }
    }
  }

  &.thumbSwiper {
    .swiper-pagination {
      position: relative !important;
      bottom: unset !important;
      // top: 103px !important;
      bottom: 3px !important;

      .swiper-pagination-bullet {
        height: 5px !important;
        width: 3rem !important;

        @include responsivemin(540) {
          width: 3rem !important;
        }

        @include responsivemin(1400) {
          width: 4rem !important;
        }
      }

      @include responsivemin(1024) {
        // top: 118px !important;
        bottom: 3px !important;
      }

      @include responsivemin(1400) {
        // top: 140px !important;
        bottom: 3px !important;
      }
    }

    &.mobHidePagination {
      .swiper-pagination {
        display: none;

        @include responsivemin(1400) {
          display: block;
        }
      }
    }
  }
}
.floating_social {
  top: 50%;
  left: -11.5rem;
  // top: 67%;
  // left: -15rem;
  // @include responsivemax(540) {
  //   top: 63%;
  //   left: -15rem;
  // }
  // @include responsivemax(492) {
  //   top: 63%;
  //   left: -14.5rem;
  // }
  
  @include responsivemin(320) {
    top: 50%;
    left: -11.5rem;
  }

  @include responsivemin(768) {
    top: 50%;
    left: -11.50rem;
  }
  a {
    transition: 0.5s all ease;
    -webkit-animation: colorchangetext 20s infinite;
    -moz-animation: colorchangetext 20s infinite;
    -o-animation: colorchangetext 20s infinite;
    -ms-animation: colorchangetext 20s infinite;
    animation: colorchangetext 20s infinite;
    &:hover {
      transform: scale(1.2);
    }

    @-webkit-keyframes colorchangetext {
      0% {
        color: #d35400;
      }
      10% {
        color: #8e44ad;
      }
      20% {
        color: #1abc9c;
      }
      30% {
        color: #d35400;
      }
      40% {
        color: white;
      }
      50% {
        color: #3e76ae;
      }
      60% {
        color: rgb(153, 153, 253);
      }
      70% {
        color: #2980b9;
      }
      80% {
        color: #f1c40f;
      }
      90% {
        color: #2980b9;
      }
      100% {
        color: pink;
      }
    }
    @keyframes colorchangetext {
      0% {
        color: #d35400;
      }
      10% {
        color: #8e44ad;
      }
      20% {
        color: #1abc9c;
      }
      30% {
        color: #d35400;
      }
      40% {
        color: white;
      }
      50% {
        color: #3e76ae;
      }
      60% {
        color: rgb(153, 153, 253);
      }
      70% {
        color: #2980b9;
      }
      80% {
        color: #f1c40f;
      }
      90% {
        color: #2980b9;
      }
      100% {
        color: pink;
      }
    }
  }
}

// Slider V2

.dark .thumbSwiper {
  .thumbImage {
    border: 1.78px solid #454545;
    box-shadow: 0px 0px 14px -2px #454545;
  }
  .swiper-slide-active
  // ,
  // .swiper-slide-thumb-active
  {
    .thumbImage {
      border: 1.78px solid #a96ef5;
      box-shadow: 0px 0px 14px -2px #a96ef5;
    }
  }
}
.thumbSwiper {
  .thumbImage {
    border: 1.78px solid #454545;
    box-shadow: 0px 0px 14px -2px #454545;
  }

  .swiper-slide-active // ,

  // .swiper-slide-thumb-active
  {
    .thumbImage {
      border: 1.78px solid #0284c7;
      box-shadow: 0px 0px 14px -2px #0284c7;
    }
  }
}

/*============== Swiper Content Animation =================*/

.animateSlider {
  .swiper-slide-active {
    .slider-sub-title {
      animation: rollIn 1s;
    }
  }

  .swiper-slide-active {
    .slider-title {
      animation: fadeInUp 1.5s;
    }
  }
  .swiper-slide-active {
    &.slide-2 {
      .slider-title {
        animation: fadeInRight 1.5s;
      }
    }
  }
  .swiper-slide-active {
    &.slide-3 {
      .slider-title {
        animation: fadeInLeft 1.5s;
      }
    }
  }

  .swiper-slide-active .slider-buttton {
    animation: zoomIn 2s;
  }
}

/*============== Swiper Content Animation =================*/
